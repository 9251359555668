@import '/src/css/General.scss';

.EditMoviePage
{

	.create-caption
	{
		@extend .caption;
		@extend .bold;
		color: color('primary');
		text-align: center;
		margin-top: size(32);
		white-space: pre-wrap;
		display: flex;
		align-items: center;
		justify-content: center;
		&::before, &::after
		{
			content: '';
			border-left: 2px solid color(primary);
			height: size(40);
			display: inline-block;
			transform-origin: bottom;
		}
		&::before
		{
			transform: translateX(-#{size(6)}) translateY(size(-2)) rotate(-30deg);
		}
		&::after
		{
			transform: translateX(#{size(6)}) translateY(size(-2)) rotate(30deg);
		}

	}
}

