@import 'src/css/General.scss';

.Recorder
{
	background-color: color('text');
	min-height: 100vh !important;
	overflow-y: visible;

	.wrapper
	{
		padding-bottom: size(64);
	}
	.preview
	{
		z-index: 100;
		position: relative;
		.video
		{
			width: min(768px, 100vw);
			height: min(768px, 100vw);
			transform: rotateY(180deg);
		}
		&.previewing .video, &.back .video
		{
			transform: rotateY(0deg);
		}

		.ui
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			.play-icon
			{
				position: absolute;
				width: size(75);
				height: auto;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}

			.countdown
			{
				position: absolute;
				font-size: size(64);
				width: 38%;
				height: 38%;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}

			.guide
			{
				display: flex;
				flex-direction: column;
				align-items:center;
				position: absolute;
				top: size(47);
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				bottom: 0;
				// .guide-face
				// {
				// 	width: size(178);
				// 	height: size(178);
				// 	transform: scaleX(0.871);
				// 	border-radius: size(148);
				// 	border: dashed size(7) color('white');
				// }

				.bubble
				{
					// margin-top: size(20);
					bottom: size(32);
					position: absolute;
					width: size(311);
					white-space: pre-wrap;
				}
			}
		}
	}
	.action
	{
		padding: size(24) size(24) size(0) size(24);
		// min-height: size(150);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		.round-button
		{
			margin-bottom: 0;
		}
		.speech-bubble
		{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: size(12);
			.small
			{
				.item
				{
					@extend .caption;
					@extend .bold;
					display: flex;
					align-items: center;
					justify-content: center;
					*
					{
						display: inline;
					}
					.icon
					{
						width: size(12, 12);
						height: size(12, 12);
						margin-right: size(6, 12);
					}
				}
			}
		}
	}
	.buttons
	{
		padding: 0 size(24);
	}
	.stop-button
	{
		@extend .bold;
		margin-top: size(30, 12);
		margin-bottom: size(24, 12);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: size(12);
		color: color('subtext');
		text-align: center;
		i
		{
			margin-right: size(8);
		}
	}

}