@import 'Responsive.scss';
@import 'Colors.scss';
@import 'src/font/icons/icons.scss';

$breakpointsFontSizes: (
    'small': 15,
    'medium': 16,
    'large': 18,
);

@each $k, $v in $breakpointsFontSizes {
	@include breakpoint($k)
	{
		body
		{
			--base-size: #{$v};
		}
	}
}



@function size($size, $breakpoint: 'small', $baseValue: 0)
{
	@if (type-of($breakpoint) == 'number')
	{
		$baseValue: $breakpoint;
	}
	@if ($baseValue == 0) {
		$baseValue: map-get($breakpointsFontSizes, $breakpoint);
	}
	@return ($size / $baseValue) * 1em;
}


@function rem($size)
{
	@return calc(#{$size} / var(--base-size) * 1rem);
}

@mixin fontFamily($path, $name, $style: 'normal', $weight: 'normal')
{
	@font-face {
		font-family: $name;
		src: url($path);
		font-style: $style;
		font-weight: $weight;
	}
}

@include fontFamily('../font/MPLUSRounded1c-ExtraBold.ttf', 'mplusrounded', 'normal', 800);
@include fontFamily('../font/NotoSansJP-Medium.otf', 'notosansjp', 'normal', 400);
@include fontFamily('../font/NotoSansJP-Bold.otf', 'notosansjp', 'normal', 700);
@include fontFamily('../font/NotoSansJP-Black.otf', 'notosansjp', 'normal', 900);
@include fontFamily('../font/Lato-Regular.ttf', 'lato', 'normal', 400);
@include fontFamily('../font/Lato-Bold.ttf', 'lato', 'normal', 700);
@include fontFamily('../font/Lato-Black.ttf', 'lato', 'normal', 900);

* {
	// font-family : 'notosansjp';
	font-weight: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	vertical-align: baseline;
}

body
{
	color: color(text);
	font-weight: normal;
	// font-size: (map-get($breakpointsFontSizes, 'medium') / 1440) * 100vw;
	font-size: map-get($breakpointsFontSizes, 'medium') + px;
	line-height: 1.5;
	background-color: color('white_bg');
	// @include breakpoint('medium')
	// {
	// 	background-color: color('white');
	// }
	@include breakpoint('small')
	{
		background-color: color('white');
		font-size: (map-get($breakpointsFontSizes, 'small') / 375) * 100vw;
	}
	font-family : 'notosansjp', 'notosansjp';

	&.ja
	{
		font-family : 'notosansjp';
	}
	&.en
	{
		font-family : 'notosansjp';
	}
}

h1, .h1
{
	.ja &
	{
		font-size: size(24, 'small');
	}

	.en &
	{
		font-size: size(26, 'small');
	}
	font-weight: bold;
}

h2, .h2
{
	.ja &
	{
		font-size: size(20, 'small');
	}
	.en &
	{
		font-size: size(22, 'small');
	}
	font-weight: bold;
	margin: size(30, 20) 0 size(20, 20) 0;
}

h3, .h3
{
	.ja &
	{
		font-size: size(17, 'small');
	}
	.en &
	{
		font-size: size(18, 'small');
	}
	font-weight: bold;
}

b, .bold
{
	font-weight: bold;
}

.text
{
	.ja &
	{
		font-size: size(15, 'small');
	}
	.en &
	{
		font-size: size(16, 'small');
	}
}

.caption
{
	.ja &
	{
		font-size: size(12, 'small');
	}
	.en &
	{
		font-size: size(13, 'small');
	}
}


i, i::before
{
	font-size: inherit;
}

button
{
	color: color('text');
}

small
{
	font-weight: normal;
}