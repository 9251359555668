@import 'src/css/General.scss';

.modal-editCovertitle{
	.modal-container{
		position: relative;

		.title{
			font-weight: 700;
			font-size: 20px !important;
		}
	
		.previewbox{
			position: relative;
			width: 200px;
			height: 200px;
			margin: 24px auto;
			

			.preview-image{
				position: relative;
				width: 100%;
				height: 100%;
				border-radius: 4px;

				display: flex;
				justify-content: center;
				align-items: center;

				img{
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 4px;
					margin: 0;
				}
			}

			#previewTextArea{
				max-width: 200px;
				color: color('primary');
				font-weight: 700;
				font-size: 18px;
				overflow: hidden;
				max-height: calc((18px * 1.5) * 4);
			}
		}

		.inputbox{
			.inputheader{
				display: flex;
				font-size: 12px;
				font-weight: 700;

				span{
					color: color('subtext');
					margin-left: 2px;
				}
			}
			// textarea{
			// 	border-radius: 16px;
			// 	padding: 20px;
			// 	width: 100%;
			// 	margin-top: 11px;
			// 	border: 1px solid color('border2');
			// 	background-color: color('white2');
			// 	height: 111px;

			// 	font-size: 15px;
			// 	font-weight: 500;
			// }

			.input-field{
				margin-bottom: 0;
			}

			.textValidation_errormessage{
				display: none;
				color: color('error');
				font-size: 12px;
				font-weight: 700;
				margin-top: 4px;
				text-align: center;
				&.show
				{
					display: block;
				}
			}
		}

		.buttons{
			margin-top: 24px;
		}

	}

}