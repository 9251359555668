@import '/src/css/General.scss';

.modal
{
	.title
	{
		font-size: size(20);
		font-weight: bold;
	}
	[class="modal-container"]
	{
		overflow: hidden;
	}
	.bg
	{
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		margin: 0;
		z-index: 0;
	}
	.instructions
	{
		width: 90%;
		margin-left: 5%;
	}

	.note
	{
		font-size: size(10.2);
		font-weight: bold;
		color: color('subtext');
	}
}