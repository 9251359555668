@import '/src/css/General.scss';

.BusinessPage
{
	.content
	{
		white-space: pre-wrap;
		margin-top: size(40);
		border-top: 1px solid color('border');
		>.item
		{
			// @extend .bold;
			border-bottom: 1px solid color('border');
			padding: size(40) 0;

			>.title
			{
				@extend .h2;
				margin-top: 0;
			}

			ul
			{
				margin: 1em 0;
				li
				{
					margin-left: 2em;
					list-style: disc;
				}
			}

			.pricing
			{
				margin: size(40) 0 0;
				.line
				{
					display: flex;
					width: 100%;
					align-items: center;
					
          flex-wrap: wrap;
					margin-bottom: size(20);
					.separator{
						height: 1px;
						border-top: color('subtext') dashed 1px;
						flex-grow: 1;
						margin-top: 0.9em;
						// flex-shrink: ;
						margin-left: size(10);
						margin-right: size(10);
						height: 1em;
					}
          .break
          {
            flex-basis: 100%;
            height: 0;
          }
					.label, .price
					{
						@extend .h3;
					}
					.note
					{
						@extend .caption;
						@extend .bold;
            margin-top: rem(5);
					}
          .price-container
          {
            flex: 1;
            display: flex;

          }
				}
				.description
				{
				}
			}
		}
	}
}