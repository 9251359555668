@import '/src/css/General.scss';

.PreviewMoviePage
{
	h2
	{
		margin-bottom: size(16, 28);
	}

	.buttons
	{
	}
	
	.preview-video
	{
		border-radius: size(16);
		overflow: hidden;
		margin-top: size(32);
		margin-bottom: size(36);
		
		width: calc(min(768px, 100vw) - #{size(48)});
		height: calc(min(768px, 100vw) - #{size(48)});
		border-radius: size(16);
		// .video
		// {
		// 	position: absolute;
		// 	top: -10000px;
		// 	width: 100px;
		// 	height: 100px;
		// 	left: 0;
		// 	// visibility: hidden;
		// }
		.preview-canvas
		{
			border-radius: size(16);
			width: 100%;
			height: 100%;
		}
		.play-icon
		{
			position: absolute;
			width: size(75);
			height: auto;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		img
		{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.3s;
		}
		&.paused
		{
			img
			{
				opacity: 1;
				visibility: visible;
			}
		}

		.loading-wrapper
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			.loader
			{
				background-color: rgba(0, 0, 0, 0.8);
			}
			.loading-content, .loader
			{
				position: absolute!important;

			}
			.loading-content
			{
				.loading-message
				{
					color: color('white');
				}
			}
		}
	}
}