@import 'src/css/General.scss';


.title
{
	@extend .h3;
	margin-bottom: rem(24)!important;
}

.items
{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: rem(24);
	gap: rem(16);

	li
	{
		width: rem(134);
		margin-bottom: rem(8);
		.thumb
		{
			width: 100%;
			margin-right: rem(20);
			flex-shrink: 0;
			margin-bottom: rem(12);
			height: rem(134);
			img
			{
				margin: 0;
				border-radius: rem(16);
				width: rem(134);
				height: rem(134);
			}
			.play-icon
			{
				width: rem(40);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.caption
		{
			white-space: nowrap;
			line-height: 1;
			font-size: rem(15);
			color: color('subtext');
			font-weight: bold;
			text-align: center;
		}

	}
}
