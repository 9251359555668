@import 'src/css/General.scss';

@mixin padding
{
	padding: 0 size(24);
}

@mixin padding-large
{
	padding: 0 size(32);
}

.Page, [class*=" page-"]
{
	@include padding;
	margin: size(24) 0;
	min-height: calc(100vh - #{size(260)});

	.noscroll &
	{
		overflow: hidden;
	}

	[class*=" page-"]
	{
		padding: 0;
		min-height: unset;
	}

	.standalone &
	{
		padding: 0 !important;
		margin: 0 !important;
	}	
}
.no-padding [class*=" page-"]
{
	padding: 0;
}

.full-width
{
	padding: 0;
	margin-left: size(-24);
	width: min(768px, 100vw);
  max-width: calc(100% + (1.6em * 2));
}

.padding
{
	@include padding;
}