@import 'src/css/General.scss';

.modal
{
	.title
	{
		@extend .h3;
		text-align: center;
		margin-bottom: rem(16)!important;
		margin-top: 0!important;
		line-height: 1!important;
	}
	.description
	{
		font-weight: bold;
		text-align: center;
		margin-bottom: rem(12)!important;
		color: color('text')!important;
	}

	form
	{
		text-align: left;
	}

  .mail-magazine{
		margin-top: rem(32);
		margin-bottom: rem(12);
		// font-size: rem(12);
  }
	.terms
	{
		margin-bottom: rem(32);
    .note-title
    {
      font-size: rem(12);
      font-weight: bold;
      color: color('subtext');
			margin-bottom: 0.4em;
    }
		a
		{
			text-decoration: underline;
		}
		li
		{
			font-weight: bold;
			font-size: rem(12);

			color: color('subtext');
			display: flex;
			align-items: flex-start;
			margin-bottom: 0.4em;
      span{
        white-space: pre-wrap;
      }
			&>
			{
				text-align: left;
				align-items: flex-start;
			}
		}
		a[href='/ja/contact/']{
			i{
				display: none;
			}
		}

	}	

	.already-registered
	{
		margin-top: rem(26);
		display: block;
		font-size: rem(12);
		font-weight: bold;
		color: color('subtext');
		text-align: center;
	}
}