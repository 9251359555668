@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	background-color: color('white');
	padding-top: size(75)!important;
	padding-bottom: size(80);

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	ul.items
	{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: size(-24);
		gap: 15px;

		li
		{
			width: size(148);
			margin-bottom: size(24);
			.image
			{
				width: 100%;
				margin-right: size(20);
				flex-shrink: 0;
				margin-bottom: size(16);
				height: size(148);
				img
				{
					border-radius: size(16);
					width: size(148);
					height: size(148);
				}
				.play-icon
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.caption
			{
				font-size: size(12);
				font-weight: bold;
				text-align: center;
			}

		}
	}
	.highlight
	{
		font-weight: bold;
		font-size: size(17);
		color: color('subtext');
		text-align: center;
		margin-top: size(40, 17);
		margin-bottom: size(3, 17);
	}

}