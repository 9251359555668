@import 'src/css/General.scss';

.inviteContent
{
	h2
	{
		margin-top: 0;
		text-align: left;

	}
	.description
	{

	}
	.expiration
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		margin-top: size(12, 12);
		margin-bottom: size(40, 12);
		text-align: center;
	}
	.participate
	{
		margin-top: size(32);
		padding-bottom: size(12);
		.title
		{
			@extend .caption;
			@extend .bold;
		}
		.content
		{
			@extend .caption;
			@extend .bold;
			border-radius: size(16, 12);
			border: 1px dashed color('inactive');
			padding: size(42, 12) size(22, 12) size(22, 12);
			margin: size(12, 12) 0;
			text-align: left;
			
			white-space: pre-wrap;

			.copy
			{
				// @extend .caption;
				@extend .bold;
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				right: 0;
				color: color('subtext');
				background-color: color('white2');
				padding: size(2, 12) size(8, 12);
				margin: size(10, 12) size(8, 12);
				border-radius: 2em;
				.icon
				{
					font-size: size(16, 12);
				}
			}

			&>div
			{
				margin-bottom: 1.5em;
			}

			a
			{
				color: color('primary');
				word-break: break-all;
			}
		}
	}
}