@import 'src/css/General.scss';

.round-button.line
{
	.icon
	{
		position: absolute;
		left: 0.8em;
		margin-top: -0.5em;
		width: 2.5em;
	}
	.label
	{
		display: inline-block;
		align-items: center;
		width: 100%;
	}
}
