@import 'src/css/General.scss';

// :global
// {
// 	section
// 	{
// 		background-color: red!important;
// 	}
// }

.body
{
	:global #root
	{
		background-color: color('white2') !important;
		header.header,
		.App{
			background: none !important;
			padding-bottom: 0;
		}

		section
		{
			margin: 0;
			padding: 0;
		}
	}
}

.confetti{
	position: fixed;
	top: 0;
	width: 100%;
	max-width: 25em;
}
.header{
	margin-top: rem(5);
	padding: 0 1.6em;

	.back-button{
		color: color('subtext');
		font-size: rem(12);
		font-weight: bold;
		display: flex;
		align-items: center;
		background-color: color('white');
		padding: rem(7) rem(13);
		border-radius: rem(32);

		i{
			font-size: rem(16);
			margin-bottom: rem(-1);
			margin-right: rem(4);
		}
	}
}

.container
{
	background-color: color('white');
	box-shadow: rem(0) rem(14) rem(36) rgba(0, 0, 0, 0.15);
	margin-top: rem(17);
	padding: rem(32) rem(24) rem(12) rem(24);
	border-radius: rem(43) rem(43) 0 0;
	min-height: calc(100vh - #{size(260)});
	// height: 100px;
}
