@import 'src/css/General.scss';

.MoviesListPage
{
	.movies
	{
		a
		{
			width: 100%;
			display: block;
		}

		li
		{
			border: 1px solid color('border2');
			padding: size(30) size(20);
			border-radius: size(16);
			margin-bottom: size(24);
			&.expired
			{
				border-color: color('white2');
				background-color: color('white2');
				pointer-events: none;
			}

			.arrow
			{
				position: absolute;
				font-size: size(20);
				top: size(26, 20);
				right: size(18, 20);
			}
			
			.movieId
			{
				margin-top: size(4);
				margin-bottom: size(16);
			}

			div
			{
				@extend .bold;
				span
				{
					@extend .caption;
				}
				.label
				{
					color: color('subtext');
					margin-right: size(8, 12);
				}
				.expired-label
				{
					margin-left: size(4, 12);
					color: color('error');
				}
			}

		}
	}
	.description
	{
		color: color('subtext');
		margin-bottom: size(30);
    white-space: pre-wrap;
	}
}