@import 'src/css/General.scss';

.panel
{
	background-color: color('errorLight');
	border: 1px solid color('error');
	padding: size(16);
	border-radius: size(16);
	text-align: center;
	margin: size(30) 0;
	color: color('text');

	p
	{
		margin: 0.6em 0;
		&:first-child
		{
			margin-top: 0;
		}
		&:last-child
		{
			margin-bottom: 0;
		}
	}

	.subtext
	{
		color: color('subtext');
	}

	
	.icon
	{
		line-height: 1em;
		display: block;
		margin-bottom: size(6);
	}
	.content
	{
		@extend .bold;
		font-size: size(12);
		a
		{
			margin-top: size(6);
		}
	}
}