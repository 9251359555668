$colors: (
  'title': #443D3D,
    'primary': #F75129,
	'primary2': #CA5940,
	'alt': #E28B55,
    'secondary': #6CAFD4,
	'inactive': #CFCBCB,
	'error': #E43737,
	'errorLight': #FFE9E0,
	'ok': #55B06F,
	'text': #443D3D,
	'subtext': #938D8D,
	'themetext': #443D3D,
	'themesubtext': #938D8D,
	'footer': #D0D1D4,
	'white': #FFFFFF,
	'white2': #F8F6F1,
	'white_bg': #EEECE6,
	'loading_bg': #EEECE6,
	'gradation_bg': #EEECE6,
	'black': #22252B,
	'gray': #8C8684,
	'gray2': #86868A,
	'border': #BEBBB9,
	'border2': #E7E4E4,
	'placeholder': #C4C4C4,
	'shadow': #F1EFE9,
);


$themes: (
	'cake': (
		'title': #FB87FB,
    'primary': #FB87FB,
		'primary2': #CD5BCD,
		'alt': #FB87FB,
		'white_bg': #EAEAEA,
		'loading_bg': #EAEAEA,
	),
	'party': (
		'title': #353535,
    'primary': #353535,
		'primary2': #000000,
		'alt': #353535,
		'white_bg': #F4F6F1,
		'loading_bg': #F4F6F1,
	),
	'natural': (
		'title': #CDAC33,
    'primary': #CDAC33,
		'primary2': #A88B21,
		'alt': #CDAC33,
		'white_bg': #F8F6F1,
		'loading_bg': #F8F6F1,
	),
	'triangle': (
		'title': #487E6A,
    'primary': #487E6A,
		'primary2': #30614F,
		'alt': #487E6A,
		'white_bg': #C8EEEC,
		'loading_bg': #E5E5E5,
	),
	'cat': (
		'title': #D3AF41,
    'primary': #D3AF41,
		'primary2': #A88B21,
		'alt': #D3AF41,
		'white_bg': #F8F6F1,
		'loading_bg': #F8F6F1,
	),
	'fireworks': (
		'title': #A9861A,
    'primary': #A9861A,
		'primary2': #826714,
		'alt': #A9861A,
		'white_bg': #8E741E,
		'gradation_bg': #FFFFFF,
		'loading_bg': #8E741E,
	),
	'bouquet': (
		'title': #FF8A43,
    'primary': #FF8A43,
		'primary2': #D0692B,
		'alt': #FF8A43,
		'white_bg': #F8FAFE,
		'loading_bg': #F8FAFE,
	),
	'bubbles': (
		'title': #60AFB6,
    'primary': #60AFB6,
		'primary2': #408F96,
		'alt': #60AFB6,
		'white_bg': #F8FAFE,
		'loading_bg': #F8FAFE,
	),
	'teddybears': (
		'title': #FF7070,
    'primary': #FF7070,
		'primary2': #B71D4B,
		'alt': #FF7070,
		'white_bg': #D6A493,
		'loading_bg': #D6A493,
		'themetext': #FFFFFF,
		'themesubtext': #FFFFFF,
	),
	'origamicrane': (
		'title': #0640D3,
    'primary': #0640D3,
		'primary2': #030B53,
		'alt': #0640D3,
		'white_bg': #E3E3E3,
		'loading_bg': #E3E3E3,
	),
	'chicks': (
		'title': #CFAD0F,
    'primary': #CFAD0F,
		'primary2': #727411,
		'alt': #CFAD0F,
		'white_bg': #D8EBED,
		'loading_bg': #D8EBED,
	),
	'cherryblossom': (
		'title': #CFAD0F,
    'primary': #CFAD0F,
		'primary2': #727411,
		'alt': #CFAD0F,
		'white_bg': #F2E8F3,
		'loading_bg': #F2E8F3,
	),
	'giftbox': (
		'title': #D84100,
    'primary': #D84100,
		'primary2': #A32903,
		'alt': #D84100,
		'white_bg': #FCFFDC,
		'loading_bg': #FCFFDC,
	),
	'partypoppers': (
		'title': #B5B900,
    'primary': #B5B900,
		'primary2': #988903,
		'alt': #B5B900,
		'white_bg': #27003F,
		'loading_bg': #27003F,
		'themetext': #FFFFFF,
		'themesubtext': #FFFFFF,
	),
	'popping_flowers': (
		'title': #F75129,
    'primary': #F75129,
		'primary2': #CA5940,
		'alt': #F75129,
		'white_bg': #FCFCFC,
		'loading_bg': #FCFCFC,
	),
	'warmth': (
			'title': #19A9FA,
      'primary': #19A9FA,
			'primary2': #048FDE,
			'alt': #19A9FA,
			'white_bg': #F5E7D6,
			'loading_bg': #F5E7D6,
	),
	'daisy': (
			'title': #F75129,
      'primary': #F75129,
			'primary2': #CA5940,
			'alt': #F75129,
			'white_bg': #0E71C1,
			'loading_bg': #0E71C1,
			'themetext': #FFFFFF,
			'themesubtext': #938D8D,
	),
	'geometry': (
			'title': #2C00DF,
      'primary': #2C00DF,
			'primary2': #18007B,
			'alt': #2C00DF,
			'white_bg': #FCFCFC,
			'loading_bg': #FCFCFC,
	),
	'japanese_pattern': (
			'title': #F75129,
      'primary': #F75129,
			'primary2': #CA5940,
			'alt': #F75129,
			'white_bg': #F2ECE7,
			'loading_bg': #F2ECE7,
	),
	'grace': (
			'title': #F75129,
      'primary': #F75129,
			'primary2': #CA5940,
			'alt': #F75129,
			'white_bg': #ABA3CE,
			'loading_bg': #ABA3CE,
			'themetext': #FFFFFF,
			'themesubtext': #938D8D,
	),	
);

@mixin setColors()
{
	:root
	{
		@each $k, $v in $colors {
			--#{$k}: #{$v};
		}
	}
	.theme-default
	{
		@each $k, $v in $colors {
			--#{$k}: #{$v};
		}
	}
	
	@each $k, $v in $themes {
		.theme-#{$k}
		{
			@each $tk, $tv in map-get($themes, $k) {
				--#{$tk}: #{$tv};
			}
		}
	}
}


@function color($name, $asHex: false)
{
	@if $asHex == true
	{
		@return map-get($colors, $name);
	}
	@return var(--#{$name});
}

