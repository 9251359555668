@use './AlertPanel.scss';
@import 'src/css/General.scss';

.panel
{
	.checkbox
	{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: size(12);
		[class='check-box']
		{
			align-items: center;
			[class='check-bullet']
			{
				margin-bottom: -0.05em;
				margin-right: 0.4em;
				background-color: transparent;
				border-color: color('black');
				width: 1em;
				height: 1em;
				border-radius: 0;
				[class='check']
				{
					display: none;
				}
			}
		}
	}
	// .icon
	// {
	// 	line-height: 1em;
	// 	display: block;
	// 	margin-bottom: size(6);
	// }
	// .content
	// {
	// 	@extend .bold;
	// 	font-size: size(12);
	// 	a
	// 	{
	// 		margin-top: size(6);
	// 	}
	// }
}