@import 'src/css/General.scss';

.InviteMoviePage
{
	.description
	{

	}
	.expiration
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		margin-top: size(12, 12);
		margin-bottom: size(40, 12);
		text-align: center;
	}
	.participate
	{
		padding-bottom: size(12);
		.title
		{
			@extend .caption;
			@extend .bold;
		}
		.content
		{
			@extend .caption;
			@extend .bold;
			border-radius: size(16, 12);
			border: 1px dashed color('inactive');
			margin: size(12, 12) 0;
			
			white-space: pre-wrap;

			a
			{
				color: color('primary');
				word-break: break-all;
			}
		}
	}
}