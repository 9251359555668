@import '/src/css/General.scss';

.modal
{
	overflow: hidden;
	[class="modal-padding"]
	{
		[class="modal-container"]
		{
			padding: 0!important;
			background-color: color('white2');
		}
	}

	.skip-btn
	{
		display: flex;
		align-items: center;
		position: absolute;
		top: rem(6);
		right: rem(7);
		color: color('subtext');
		font-weight: bold;
		font-size: rem(12);
		z-index: 10;
		&.hidden
		{
			visibility: hidden;
		}
		i
		{
			font-size: 1.4em;
			margin-top: 0.1em;
		}
		
	}

	.scrollbar-container
	{
		width: 100%;
		margin-bottom: rem(-20);
		overflow: hidden;
		&::-webkit-scrollbar{
			display: none;
		}
		.slider
		{
			display: flex;
			overflow-x: scroll;
			scroll-snap-type: x mandatory;
			scroll-snap-align: center;
			width: 100%;
			padding-bottom: rem(20);
			&::-webkit-scrollbar{
				display: none;
			}
	
			.item
			{
				scroll-snap-align: start;
				width: 100%;
				flex: 0 0 100%;
				padding: rem(37) rem(10) 0 rem(10);

				.content
				{
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					.header
					{
						.title
						{
							font-size: rem(20);
							font-weight: bold;
							margin-bottom: rem(6)!important;
							b
							{
								color: color('primary');
							}
						}
						.description
						{
							font-size: rem(12);
							font-weight: bold;
							color: color('subtext');
						}
					}

					&.image-bottom
					{
						padding-top: rem(56);
						.header
						{
							top: 0;
							position: absolute;
							z-index: 2;
							width: 100%;
              height: rem(120);
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
							.title
							{
							}
							.description
							{
	
							}
						}
	
						.image
						{
							margin: 0;
							padding: 0;
							border: 0;
							border-collapse: collapse;
							white-space-collapse: collapse;
							display: flex;
							img
							{
								width: rem(303);
								height: auto;
								margin-bottom: 0;
								border: 0;
							}
						}
					}
					&.last-page
					{
						.header
						{
							margin-bottom: rem(26);
						}

						.text-content
						{
							display: flex;
							flex-direction: column;
							align-items: center;
							.text
							{
								color: color('text');
								font-weight: bold;
								font-size: rem(12);
								margin-bottom: rem(15);
								white-space: pre;
								b
								{
									color: color('primary');
								}
							}

							.bullet-points
							{
								width: rem(268);
								text-align: left;
								border: 1px solid color('subtext');
								border-radius: rem(13);
								padding: rem(14);
								.title
								{
									text-align: left;
									font-size: rem(12);
								}

								.items
								{
									li
									{
										font-size: size(12);
										font-weight: bold;
										color: color('subtext');
										display: flex;
										align-items: start;
										margin-bottom: rem(5);
										&:last-of-type
										{
											margin-bottom: 0;
										}
										.bullet
										{
											background-color: color('ok');
											display: inline-flex;
											align-items: center;
											justify-content: center;
											width: rem(16);
											height: rem(16);
											border-radius: rem(16);
											color: color('white');
											margin-right: rem(6);
											transform: translateY(rem(1));
											&::before
											{
												font-size: rem(12);
												margin-top: -0.1em;
												margin-right: -0.1em;
											}
										}
									}
								}
							}
						}

						.buttons
						{
							margin-top: rem(26);

							.link
							{
								font-size: rem(12);
								color: color('subtext');
								display: flex;
								align-items: center;
								justify-content: center;

								.close-icon
								{
									display: inline-block;
									margin-bottom: -0.2em;
									margin-right: rem(5);
									i
									{
										font-size: rem(16);
									}
								}
							}
						}
					}
				}
			}
		}
	}


	.pagination
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: color('text');
		border-bottom-left-radius: size(16);
		border-bottom-right-radius: size(16);

		.bullets
		{
			display: flex;
			align-items: center;
			justify-content: center;
			gap: rem(8);
			height: rem(58);
			.bullet
			{
				display: block;
				width: rem(8);
				height: rem(8);
				border-radius: rem(4);
				background-color: color('white');
				transition: background-color 0.1s ease-out;
				&.selected
				{
					background-color: color('primary');
				}
			}
		}

		.arrow
		{
			margin-top: rem(1);
			margin-left: rem(16);
			margin-right: rem(16);
			visibility: hidden;
			pointer-events: none;
			&.active
			{
				visibility: visible;
				pointer-events: all;
			}
			&.left
			{
				transform: scaleX(-1);
			}
			i{
				font-size: rem(20);
				color: color('white')!important;
			}

		}
	}
}