@import 'src/css/General.scss';
@import 'src/core/screens/Page.scss';

.ReorderMoviePage
{
	.participants
	{
		@extend .full-width;
		padding: size(8) 0;
		background-color: color('white2');
		margin-bottom: size(28);
		ul
		{
			li
			{
				display: flex;
				align-items: center;
				padding: size(8) size(20) size(8) size(16);
				margin: size(8) size(24);
				justify-content: stretch;
				background-color: color('white');
				border-radius: size(16);
				.thumb
				{
					width: size(40);
					height: size(40);
					border-radius: 2em;
					overflow: hidden;
					margin-right: size(8);
					display: flex;
					align-items: center;
					justify-content: center;
					img
					{
						width: 100%;
						height: auto
					}

				}
				.index
				{
					@extend .bold;
					display: inline-block;
					font-size: size(11);
					color: color('gray');
					border-radius: size(36, 11);
					background-color: color('white');
					width: size(52, 11);
					text-align: center;
					padding: size(10, 11);
					margin-right: size(10, 11);
					word-break: keep-all;
				}
				.name
				{
					@extend .bold;
				}
				.you
				{
					@extend .bold;
					color: color('subtext');
					margin-left: size(4);
				}
				.spacer
				{
					flex: 1;
				}
				.icon
				{
					i
					{
						font-size: size(24);
						color: color('subtext');
					}
				}

				&.dragged
				{
					z-index: 10;
					opacity: 0.8;
					pointer-events: none;
					position: absolute;
					box-shadow: 0px 0px 4px color('gray');
					transform: scale(1.05);
					background-color: color('white');
					width: calc(min(768px, 100vw) - 48px);
				}
			}
		}
	}
}