@import '/src/css/General.scss';

.ribbonTitle
{
	font-size: size(16);
	color: color('primary');
	text-align: left;
	font-weight: 900;
	font-family: 'lato';
  display: flex;
  flex-direction: row;
  align-items: center;
	.icon
	{
		font-size: 0.8em;
	}

	.title
	{
		margin-left: size(7);
	}

	&.large
	{
		font-size: size(20, 16);

		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: size(22, 20);

		.icon
		{
			font-size: 1.4em;
		}
		.title
		{
			margin-left: 0;
			color: color('black');
		}
	}
}