.sized-video
{
	overflow: hidden;
	width: 100%;
	height: 100%;
	max-width: 100%;
	video
	{
		transform-origin: 0 0;
		width: 100%;
		height: 100%;
	}

	.video-poster
	{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-size: cover;
		z-index: 1;
	}
	img.video-overlay
	{
		position: absolute!important;
		top: 50%!important;
		left: 50%!important;
		width: 100%!important;
		height: auto!important;
		z-index: 200!important;
		transform: translate(-50%, -50%)!important;
		pointer-events: none;

	}
}