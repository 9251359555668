@import 'src/css/General.scss';

.panel
{
	background-color: color('errorLight');
	color: color('error');
	border: 1px solid color('error');
	padding: size(16);
	border-radius: size(16);
	text-align: center;
	margin: size(30) 0;
	.icon
	{
		line-height: 1em;
		display: block;
		margin-bottom: size(6);
	}
	.content
	{
		@extend .bold;
		font-size: size(12);
		a
		{
			margin-top: size(6);
		}
	}
}