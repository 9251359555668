@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	background-color: color('white');
	padding-top: size(75)!important;
	padding-bottom: size(80);

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	ul.items
	{
		margin-left: size(-8);
		margin-right: size(-8);

	}
	.pricing-item
	{
		background-color: color('white2');
		padding: size(24);
		border-radius: size(24);
		margin-bottom: size(16);
		overflow: hidden;
		.head
		{
			display: flex;
			margin-bottom: size(24);

			img
			{
				width: size(72);
				height: size(72);
				margin-right: size(12);
			}

			.info
			{
				.caption
				{
					font-size: size(10);
					font-weight: bold;
					color: color('subtext');
					line-height: 1;
					margin-bottom: size(2, 10);
				}
				.title
				{
					font-size: size(17);
					margin-bottom: size(2, 17);
					font-weight: bold;
				}
				div.price
				{
					display: flex;
					align-items: flex-end;
					span.price
					{
						font-size: size(36);
						font-weight: bold;
						line-height: 1;
            font-family: 'lato';
					}
					span.tax
					{
						font-size: size(11);
						font-weight: bold;
						color: color('subtext');
						margin-left: size(4, 11);
						margin-bottom: size(2, 11);
					}
				}
			}
		}

		ul.notes
		{
			li.note
			{
				display: flex;
				align-items: center;
				padding: 0;
				margin-bottom: size(8);
				&:last-of-type
				{
					margin-bottom: 0;
				}

				div.icon
				{
					background-color: color('ok');
					color: color('white');
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: size(14);
					width: size(16, 14);
					height: size(16, 14);
					border-radius: 2em;
					padding-left: 0.1em;
					margin-top: 0.1em;
					margin-right: size(6, 14);

					&.disabled
					{
						background-color: color('border');
						i
						{
							margin-left: -0.1em;
						}
					}
				}
				.text
				{
					font-size: size(12);
					font-weight: bold;
					color: color('subtext');
				}
				.info-button
				{
					color: color('subtext');
					font-size: size(18);
					margin-left: size(2, 18);
					margin-top: -0.5em;
					margin-bottom: -0.5em;
				}
			}

		}
	}


	.highlight
	{
		font-weight: bold;
		font-size: size(17);
		color: color('subtext');
		text-align: center;
		margin-top: size(32, 17);
		margin-bottom: size(3, 17);

	}

	.plus-icon
	{
		font-size: rem(16);
		color: color('subtext');
		margin-top: rem(16);
		margin-bottom: rem(16);
		display: block;
		text-align: center;
	}
	
	.plus-content
	{
		background-color: #EF7947;
		margin-left: rem(-8);
		margin-right: rem(-8);
		.confetti
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
		.title, .text, .caption, .price, .tax, .info-button
		{
			color: color('white')!important;
		}
	
		ul.notes
		{
			li.note
			{
				display: flex;
				align-items: center;
				padding: 0;
				margin-bottom: rem(5);
	
				div.icon
				{
					border: 1px solid color('white');
				}	
			}
		}
	}
}

