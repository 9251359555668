@import '/src/css/General.scss';

.MovieDetailPage
{
	.buttons 
	{
		text-align: center;
		.remove-btn
		{
			@extend .bold;
			line-height: 4em;
			color: color('subtext');
		}
	}

	.remove-modal
	{
		text-align: center;
		h3
		{
			margin-top: size(30, 17);
			margin-bottom: size(16, 17);
		}
		.description
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			margin-bottom: size(26, 12);
		}
	}

	// .preview
	// {
	// 	border-radius: size(16);
	// 	overflow: hidden;
	// 	width: calc(min(768px, 100vw) - #{size(48)});
	// 	height: calc(min(768px, 100vw) - #{size(48)});
	// 	background-color: color('placeholder');
	// 	margin-bottom: size(34);
	// 	.preview-video
	// 	{
	// 		width: 100%;
	// 		height: 100%;
	// 	}
	// 	.play-icon
	// 	{
	// 		position: absolute;
	// 		width: size(75);
	// 		height: auto;
	// 		top: 50%;
	// 		left: 50%;
	// 		transform: translateX(-50%) translateY(-50%);
	// 	}
	// }

	.thumbs
	{
		display: flex;
	}

}