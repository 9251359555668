@import '/src/css/General.scss';

.HomeNewsPage
{
	margin-top: 0!important;
	background-color: color('white2');
	padding-top: size(75)!important;
	padding-bottom: size(75)!important;
	.title
	{
		@extend .h2;
		text-align: center;
		margin-top: 0;
		.icon
		{
			font-size: size(22, 20);
			margin-bottom: size(5, 20);
			color: color('primary');
		}
	}
	.scrollWrapper
	{
		display: block;
		overflow-y: hidden;
		width: 100%;
		margin-top: size(48);
	}
	.items
	{		
		display: flex;
		flex-direction: row;
		overflow-x: scroll;
		width: 100%;
		padding-bottom: 50px;
		margin-top: -50px;
		top: 50px;
		padding: 0 size(12);

		&.single
		{
			justify-content: center;
		}

		.item
		{
			display: block;
			width: size(265);
			min-width: size(265);
			margin: 0 size(12);
			padding-bottom: 50px;

			.thumb
			{
				width: 100%;
				display: block;
				overflow: hidden;
				border-radius: size(16);
				width: 100%;
				height: size(149);
				background-size: cover;
				background-position: center;
				margin-bottom: size(16);
			}
			.date
			{
				@extend .bold;
				font-size: size(13);
				color: color('gray2');
				margin-bottom: size(10, 13);
			}
			.text
			{
				@extend .bold;
				font-size: size(18);
				text-align: left;
				color: color('black');
				display: -webkit-box;
				-webkit-box-orient: vertical;

				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 4;
				i
				{
					font-weight: normal;
					margin-left: size(2);
					bottom: size(-1);
				}
			}
		}
	}

	.buttons
	{
		margin-top: size(70);
	}
}