@import '/src/css/General.scss';

.highlight
{
	&::before, &::after
	{
		content: '';
		border-left: 2px solid color(subtext);
		height: 1.2em;
		display: inline-block;
		transform-origin: bottom;
	}
	&::before
	{
		transform: translateX(-0.6em) translateY(2px) rotate(-30deg);
	}
	&::after
	{
		transform: translateX(0.6em) translateY(2px) rotate(30deg);
	}
}