@import '/src/css/General.scss';


.icon
{
	font-size: rem(40);
	color: color('ok');
}

.title
{
	font-size: size(13);
	font-weight: bold;
}