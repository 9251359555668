@import 'src/css/General.scss';

.modal-purchasePlus{
	.title{
		font-weight: 700;
		font-size: 17px;
	}

	.description{
		color: color('subtext');
		font-weight: 700;
		font-size: 12px;
	}

	.images{
		display: flex;
		justify-content: space-evenly;
		margin-top: 24px;
		margin-bottom: 24px;

    &.images-1
    {
      img
      {
        width: 80%;
      }
    }

		img{
			width: 117px;
			margin: 0;
		}
	}
}