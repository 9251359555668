@import 'src/css/General.scss';

.EditAccountPage
{
	.buttons 
	{
		text-align: center;
		.remove-btn
		{
			@extend .bold;
			line-height: 4em;
			color: color('subtext');
		}
	}

	.remove-modal
	{
		text-align: center;
		h3
		{
			margin-top: size(30, 17);
			margin-bottom: size(16, 17);
		}
		.description
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			margin-bottom: size(26, 12);
		}

		.check-box
		{
			margin-top: size(40, 12);
			margin-bottom: size(36, 12);
			@extend .caption;
			@extend .bold;
			justify-content: center;
		}
	
	}


	.message
	{
		margin-bottom: size(32);
	}
  .input-field{
		margin-bottom: size(40);
  }
  .mail-magazine{
		margin-bottom: size(32);
  }
	.terms
	{
		margin-bottom: size(50);
		a
		{
			text-decoration: underline;
		}
		li
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			display: flex;
			align-items: flex-start;
			&>
			{
				text-align: left;
				align-items: flex-start;
			}
		}
	}

	.register-email-sent
	{
    .message
    {
      white-space: pre-wrap;
    }
    .note-title
    {
      font-size: rem(12);
      font-weight: bold;
      color: color('subtext');
			margin-bottom: 0.4em;
    }
		.notes
		{
			margin-bottom: size(50);
      white-space: pre-wrap;
			a
			{
				text-decoration: underline;
			}
			li
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
				display: flex;
				align-items: flex-start;
				&>
				{
					text-align: left;
					align-items: flex-start;
				}
			}
		}
	}
}