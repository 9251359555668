@import '/src/css/General.scss';

.ContactPage
{
	.description
	{
		margin-top: size(26);
		margin-bottom: size(26);
		a
		{
			@extend .bold;
			color: color('primary');
			text-decoration: underline;
		}
	}

	.email
	{
		display: block;
		margin-top: size(26);
		margin-bottom: size(26);
		.address
		{
			@extend .bold;
			display: block;
			border: 1px dashed color('border');
			text-align: center;
			padding: size(30);
			border-radius: size(16);
			margin-top: size(26);
			margin-bottom: size(26);
	
		}
		.round-button
		{
			.label
			{
				display: flex;
				align-items: center;
			}
		}
	}
	.hours
	{
		margin-top: size(60);
		.description
		{
			@extend .bold;
			border-radius: size(16);
			padding: size(32) size(16);
			background-color: color('white2');
			white-space: pre-wrap;
		}

		.alert
		{
			@extend .caption;
			@extend .bold;
			color: color('error');
			margin: size(13, 15) 0;
		}
		.note
		{
			@extend .bold;
			font-size: size(12);
			color: color('subtext');
		}

	}
}