@import '/src/css/General.scss';


.modal
{
	line-height: 1;

	.images
	{
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		margin-top: rem(24);
		margin-bottom: rem(-8);
		.image
		{
			text-align: center;
			img
			{
				width: 100%;
				height: auto;
				margin-top: 0;
			}
			.caption
			{
				margin-top: rem(8);
				font-size: rem(15);
				font-weight: bold;
				color: color('subtext');
				line-height: 1;
			}
		}
		&.image-1
		{
			width: rem(258);
			.image
			{
				width: rem(258);
			}
		}

		&.image-2
		{
			.image
			{
				width: rem(148);
				&:nth-child(2n)
				{
					margin-left: rem(16);
				}
			}
		}
	}
}
