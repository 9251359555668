@import 'src/css/reset.scss';
@import 'src/css/General.scss';

@include setColors();

body
{
	>iframe
	{
		left: -10000000px!important;
	}
	background-color: color('white');
	&.noscroll
	{
		overflow: hidden;
		height: 50vh !important;
	}
	display: flex;
	justify-content: center;
}

#root
{
	max-width: size(375);
	width: 100vw;
	position: relative;
	// background-color: color('white');

	.confetti_dashboard{
		display: none;
	}
}

.App
{
	margin-top: size(64);
	overflow-x: hidden;

	border-top-left-radius: size(48);
	border-top-right-radius: size(48);
	background-color: color('white');
	padding-bottom: size(64);
	@include breakpoint('small')
	{
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.standalone &
	{
		margin-top: 0;
		min-height: 100vh;
		margin-top: 0;
		padding-bottom: 0;
	}
}

a.disabled
{
	pointer-events: none;
}

.browser-modal
{
	.price
	{
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: size(16);
		.text, .value
		{
			@extend .bold;
		}
		.separator{
			height: 1px;
			border-top: color('subtext') dashed 1px;
			flex-grow: 1;
			margin-left: size(10);
			margin-right: size(10);
		}
	}
}
.modal_privacyupdate{
  .description{
    font-size: size(12);
    
    .card{
      @extend .bold;
      border-radius: size(16);
      padding: size(16) size(8);
      background-color: color('white2');
      white-space: pre-wrap;
      margin-top: size(16);
      margin-bottom: size(24);
  
      .head{
        color: color('subtext');
        margin-bottom: size(8);
      }
      .desc{
        color: color('black');
      }
    }
  }

}


.icon
{
	font-weight: 400!important;
}

.qr-code
{
	position: fixed;
	bottom: size(36);
	right: size(64);
	z-index: 90;
	white-space: pre-wrap;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	.label
	{
		font-size: size(12);
		font-weight: bold;
		color: color('subtext');
		margin-bottom: size(9, 12);
	}
	img
	{
		width: size(72);
		height: size(72);
	}

	@include breakpoint('small')
	{
		display: none;
	}
}