@import 'src/css/General.scss';

.speech-bubble
{
	@extend .bold;
	$color: transparentize($color: color('white', true), $amount: 0.2);
	background-color: $color;
	padding: size(20) size(26);
	border-radius: size(16);
	// display: flex;
	// flex-direction: column;
	text-align: center;
	white-space: pre-wrap;

	b
	{
		color: color('primary');
		display: inline;
	}

	i
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		font-style: normal;
		margin: size(8);

	}
	
	.arrow
	{
		width: 0; 
		height: 0; 
		position: absolute;
		border: size(10) solid transparent;
		
		&.top
		{
			border-bottom: size(10) solid $color;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
		&.left
		{
			border-right: size(10) solid $color;
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
		&.right
		{
			border-left: size(10) solid $color;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
		&.bottom
		{
			border-top: size(10) solid $color;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}

	}

	.bubble-round-tip
	{
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		&>*
		{
			fill: $color!important;
		}
	}
}