@import '/src/css/General.scss';

.error-screen
{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: size(80);
	.icon
	{
		width: size(49);
		height: size(49);
	}
	h2
	{
		margin: size(20, 20) 0 size(20, 20) 0;
	}
	.message
	{
		@extend .bold;
		margin-bottom: size(32);
	}
}