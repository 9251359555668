@import '/src/css/General.scss';

.socialIcon{
	width: 40px;
	height: 40px;
	background-color: color('white');
	border: 1px solid color('border2');
	margin-right: 11px;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	gap: 10px;

	.icon{
		color: color('primary');
		fill: color('primary');
		max-width: fit-content;
	}
}