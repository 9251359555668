@import '/src/css/General.scss';

.radio-button
{
	border: 2px solid color('border');
	padding: size(20);
	border-radius: size(13);
	input
	{
		visibility: hidden;
		position: absolute;
	}
	.radio-bullet
	{
		position: absolute;
		top: size(20);
		right: size(20);
		display: inline-flex;
		min-width: size(36);
		min-height: size(36);
		line-height: 0;
		border-radius: size(36);
		border: 1px solid color('inactive');
		background-color: color('white2');
		overflow: hidden;
		align-items: center;
		justify-content: center;
		.check
		{
			visibility: hidden;
		}

	}
	&.checked
	{
		border-color: color('primary');
		.radio-bullet
		{
			background-color: color('primary');
			.check
			{
				visibility: visible;
			}
		}
	}
}