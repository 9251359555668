@import '/src/css/General.scss';

.named-countdown
{
	color: color('white');
	display: flex;
	justify-content: center;
	align-items: center;
	height: size(75);
	.label
	{
		// @extend .h2;
		// @extend .label;
		margin: 0;

		.steps
		{
			display: flex;
			align-items: start;
			.divider
			{
				width: size(40);
				height: size(2);
				margin-top: size(14);
				border-radius: size(2);
				background-color: color('secondary');
				opacity: 0.3;
			}
			.step
			{
				display: flex;
				flex-direction: column;
				align-items: center;
				opacity: 0.3;
				&.selected
				{
					opacity: 1;
				}
				.index
				{
					font-size: size(16);
					color: color('white');
					background-color: color('secondary');
					width: size(27);
					height: size(27);
					border-radius: size(27);
					display: flex;
					line-height: size(25);
					justify-content: center;
					margin-bottom: size(4);
				}

				.label
				{
					@extend .caption;
					@extend .bold;
					color: color('white');
				}
			}
		}
	}

	.time
	{
		@extend .bold;
		border: 1px solid color('white');
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: size(4);
		padding: size(2) size(8);
		margin-left: size(8);
	}

	.bar
	{
		visibility: hidden;
	}
	&.ended
	{
		.label
		{
			@extend .h3;
		}
	}
	&.running
	{
		.label
		{
			font-size: size(12);

			&.recording-message{
				@extend .h3;
			}
		}

		.time
		{
			border: none;
			background-color: transparent;
			font-family: 'lato';
			font-size: size(39);
			margin: 0;
			padding: size(5, 39);
		}

		.bar
		{
			visibility: visible;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: size(4);
			background-color: color('white');
			.progress
			{
				height: 100%;
				background-color: color('primary');
			}
		}
	}
}