@import 'src/css/General.scss';

.large-icon-button, a.large-icon-button
{
	background-color: color('white');
	color: color('primary');
	font-size: size(15);
	line-height: 1em;
	padding: size(20, 15) size(0, 15) size(20, 15);
	border-radius: size(16);
	overflow: hidden;

	box-shadow: 0px 4px color('shadow');
	text-align: center;
	display: block;
	// flex-direction: column;
	// align-items: center;
	position: relative;

	&.disabled
	{
		color: color('inactive');
	}


	.label
	{
		margin-top: size(8);
		@extend .caption;
		@extend .bold;
		white-space: pre-wrap;
	}

	.icon
	{
		font-size: size(40);
		line-height: 1em;
		width: size(40, 40);
		height: size(40, 40);
	}

	.lockbanner{
		position: absolute;
		top: 0;
		right: 9px;
	}

}