@import 'src/css/General.scss';

.modal
{
	[class="modal-container"]
	{

		.description
		{
			font-size: rem(12);
			color: color('text');
			font-weight: bold;
		}
		.form
		{
			text-align: left;

			fieldset [class=label], .label

			{
				font-size: rem(12);
				color: color('text');
				font-weight: bold;
	
			}
			fieldset [class=description], .description
			{
				font-size: rem(12);
				color: color('subtext');
				font-weight: bold;
				padding-left: 0;
				margin-bottom: 0;
			}
		}
	}
	.link
	{
		font-size: rem(12);
		font-weight: bold;
		color: color('subtext');
		display: flex;
		i
		{
			font-size: rem(14);
			margin-top: rem(1);
			margin-right: rem(4);
			margin-left: rem(4);
		}
	}
	.help
	{
		margin-top: rem(-16);
		margin-bottom: rem(34);
	}

	.footer
	{
		.link
		{
			text-align: center;
			justify-content: center;
		}
	}
}	

.highlight
{
	color: color('primary')!important;
	font-size: rem(12)!important;
	font-weight: bold;
	margin-bottom: 0!important;
	text-align: center;

	&::before, &::after
	{
		content: '';
		border-left: 1px solid color(primary);
		height: 1.2em;
		display: inline-block;
		transform-origin: bottom;
	}
	&::before
	{
		transform: translateX(-0.6em) translateY(0) rotate(-45deg);
	}
	&::after
	{
		transform: translateX(0.6em) translateY(0) rotate(45deg);
	}

}
