@import 'src/css/General.scss';

.clipboard-toast
{
	position: fixed;
	z-index: 500000;
	top: 0;
	left: 0;
	right: 0;
	padding: size(20);
	background-color: color('ok');
	color: color('white');
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-100%);
	.icon
	{
		// color/
		margin-bottom: -0.1em;
		margin-right: size(4);
	}
	span
	{
		@extend .bold;
	}
}