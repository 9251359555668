@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';
.HomePage
{
	padding: 0!important;
	>section
	{
		margin: 0;
		.buttons
		{
			display: flex;
			flex-direction: column;
			align-items: center;
			.round-button
			{
				width: size(265);
			}
		}
	}
}