@import 'src/css/General.scss';
@import 'src/core/screens/Page.scss';

.saved-toast{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: size(20);
	color: color('white');
	display: flex;
	align-items: center;
	justify-content: center;
	@extend .bold;
	background-color: color('ok');
	transform: translateY(-100%);
	transition: transform 0.2s;
	opacity: .9;
	i
	{
		font-weight: normal;
		margin-right: 0.4em;
	}
	&.show
	{
		transform: translateY(0);
	}
	z-index: 200000;
}

.wrapper
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: calc(100vh - #{size(260)});
	// min-height: 100%;
}

.header
{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	.title
	{
		@extend .h3;
		font-size: rem(17);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: rem(8);
		.big
		{
			font-size: size(20);
			margin-bottom: rem(-6);
		}
		.small
		{
			font-size: size(15);
			margin-bottom: rem(-9);
		}
		
	}
	
	.caption
	{
		font-weight: bold;
		color: color('subtext');
		font-size: rem(12);
	}

	.left
	{
		text-align: left;
    .title
    {
      justify-content: start;
      text-align: left;
    }
	}

	.right
	{
		text-align: right;
	}
}


.body
{
	flex: 1;

	ul.items
	{
		margin-top: rem(32);
		display: flex;
		flex-direction: column;
		gap: rem(8);
		pointer-events: none;
		.dragged
		{
			position: absolute;
			width: 100%;
			z-index: 10;
			opacity: 0.8;
			pointer-events: none;
			position: absolute;
			box-shadow: 0px 0px 4px color('gray');
			transform: scale(1.05);
		}
		.item
		{
			touch-action: none;
			display: flex;
			padding: rem(8) rem(16);
			height: rem(80);
			background-color: color('white2');
			align-items: center;
			justify-content: stretch;
			border-radius: rem(16);

			.hidden
			{
				visibility: hidden;
				position: absolute;
				width: 1px;
				height: 1px;
			}

			.label
			{
				width: rem(16);
				text-align: center;
				margin-right: rem(8);
				font-weight: bold;
				color: color('primary');
				font-size: rem(12);
			}
			.spacer
			{
				flex: 1;
			}
			.thumb
			{
				width: rem(96);
				height: rem(64);
				background-size: cover;
				background-position: center;
			}

			.delete
			{
				font-weight: bold;
				color: color('primary');
				font-size: rem(12);
				margin-right: rem(24);
				pointer-events: all;
			}

			.reorder
			{
				font-weight: normal;
				color: color('subtext');
				font-size: rem(19);
				pointer-events: all;
			}
		}
	}
}


.footer
{
	margin-top: rem(24);
	.back-to-top
	{
		margin-bottom: rem(24);
		text-align: center;
		font-weight: bold;
		color: color('subtext');
		font-size: rem(12);
		i
		{
			transform: rotate(-90deg);
			font-size: size(18);
		}
	}

	.buttons
	{
		display: flex;
		width: 100%;
		gap: rem(3);
		height: rem(74);
		padding-bottom: rem(8);
		.fab
		{
			display: flex;
			width: 100%;
			gap: rem(3);
			&>button
			{
				margin: 0;
			}
	
			&.detach
			{
				position: fixed;
				bottom: 0px;
				width: auto;
				left: rem(24);
				right: rem(24);
				padding-bottom: rem(8);
			}
		}
	}
}

