@import '/src/css//General.scss';

.clipboard-toast
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: size(20);
	color: color('white');
	display: flex;
	align-items: center;
	justify-content: center;
	@extend .bold;
	background-color: color('ok');
	transform: translateY(-100%);
	transition: transform 0.2s;
	.icon
	{
		font-weight: normal;
		margin-right: 0.4em;
	}
	&.show
	{
		transform: translateY(0);
	}
	z-index: 200000;
}

.share-modal
{
	padding-top: size(68);
	padding-bottom: size(20);
	min-height: auto;
	.modal-padding
	{
		align-items: flex-start;
	}
	.title
	{
		@extend .bold;
		font-size: size(11);
	}

	.panel
	{
		border: 1px dashed color('inactive');
		border-radius: size(16);
		padding: size(34) size(20) size(12);
		text-align: left;

		.label, .value
		{
			@extend .caption;
			@extend .bold;
		}

		.value
		{
			margin-bottom: 1em;
		}

		a
		{
			word-break: break-all;
			color: color('primary');
		}
	}

	.copy
	{
		@extend .caption;
		display: inline-flex;
		align-items: center;
		background-color: #F8F6F1;
		border-radius: 2em;
		color: color('subtext');
		padding: size(2, 12) size(8, 12);
		position: absolute;
		top: size(10, 12);
		right: size(10, 12);
		.icon
		{
			font-size: size(16, 12);
		}

	}

	.buttons
	{
		.line
		{
			.label
			{
				width: 100%;
				display: inline-block;
			}
			.icon
			{
				position: absolute;
				left: 0.8em;
				margin-top: -0.5em;
				width: 2.5em;
			}
		}
		.button-icon
		{
			font-size: 1.5em;
			position: absolute;
			left: 0;
			margin-left: 0.8em;
			margin-top: -0.1em;
		}
	}
}
