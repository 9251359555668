@import '/src/css/General.scss';

.container
{
	position: absolute;
	top: 0.6em;
	left: 0.6em;
	right: 0.6em;
	bottom: 0.6em;
	display: flex;
	align-items: center;
	justify-content: center;
	.content
	{
		font-family: mplusrounded!important;
		font-weight: 800;
		color: color('primary');
		line-height: 1.1em;
		word-break: break-all;
	}
}