@import 'src/css/General.scss';
@import './Pluspage.scss';


.PlusEditPage{

	.body_card{
		.type{
			margin-bottom: 45px;

			.title{
				font-size: 17px;
				font-weight: 700;
				margin-bottom: 15px;
				display: flex;
				align-items: center;

				button{
					color: color('subtext');
					margin-left: 2px;
					font-size: 17px;

					display: flex;
					align-items: center;
				}
			}

			.slideshowButton
			{
				background-color: color('primary');
				width: 88px;
				height: 88px;
				margin-top: 10px;
				border-radius: 88px;
				color: color('white');
        white-space: pre-wrap;
				&:disabled
				{
					background-color: color('inactive');
					pointer-events: none;
				}
				.icon
				{
					font-size: size(24);
				}
				.label
				{
					margin-top: 2px;
					font-size: 10px;
					font-weight: bold;
				}
			}

			.items{
				display: flex;
				overflow-x: scroll;
				overflow-y: hidden;
				-webkit-overflow-scrolling: touch;
				z-index: 1;
				position: relative;
				left: -24px;
				width: calc(100% + 48px);
				padding: 0 8px 0 24px;

				.item{
					width: 108px;
					flex-shrink: 0;
					display: inline-block;
					margin-right: 16px;

					.icons{
						pointer-events: none;
						position: absolute;
						z-index: 1;
						right: 6px;
						top: 6px;
					}
					.thumbnail{
						position: relative;
						display: flex;

						img, video{
							width: 100% !important;
							height: auto !important;
							transform: none !important;
							border-radius: 8px;
						}

						.audiolabel{
							position: absolute;
							display: block;
							width: 100%;
							text-align: center;
							top: calc(50% - 6px);

							text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
							color: color('white');
							font-size: 12px;
							line-height: 100%;
							font-weight: 700;
							font-family: 'notosansjp';
						}
					}
					.label{
						margin-top: 6px;
						line-height: 21px;
						font-size: 12px;
						color: color('subtext');
						font-weight: 700;
					}

					.samplePlaybutton{
						margin-top: 6px;
						display: block;
						background-color: color('subtext');
						color: color('white');
						height: 21px;
						width: 100%;
						border-radius: 32px;
						font-weight: 700;
						font-size: 9.82px;
						
						&>div{
							display: flex;
							align-items: center;
							justify-content: center;
							touch-action: none;

							i{
								margin-right: 3px;
							}

							&.stop{
								display: none;
							}
						}

						&.playing{
							.play{
								display: none;
							}
							.stop{
								display: flex;
							}
						}
					}
				}
			}
		}
	}

}