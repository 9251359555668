@import 'src/css/General.scss';

.PurchasesPage
{
	.purchases
	{
		
		a
		{
			width: 100%;
			display: block;
		}

		li
		{
			border: 1px solid color('border2');
			padding: size(30) size(20);
			border-radius: size(16);
			margin-bottom: size(24);
      background-color: color('white');

			&.expired
			{
				pointer-events: none;
        border-color: color('white2');
        background-color: color('white2');
        }

			.title
			{
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: size(16);
				.text, .price
				{
					@extend .bold;
				}
				.text
				{
					font-size: size(15);
				}
				.price
				{
					font-size: size(17);
				}
				.separator{
					height: 1px;
					border-top: color('subtext') dashed 1px;
					flex-grow: 1;
					margin-left: size(10);
					margin-right: size(10);
				}

				.tax
				{
					color: color('subtext');
					font-size: size(12)!important;
					font-weight: bold!important;
					margin-left: 0.2em;
					margin-top: 0.4em;
          white-space: nowrap;
				}
			}

			.link
			{
				width: 100%;
				justify-content: flex-end;
				margin-top: size(16);
				display: flex;
				span, i
				{
					@extend .caption;
					@extend .bold;
					color: color('primary');
					line-height: 1em;
				}
				.icon
				{
					line-height: 1.2em;
				}
			}
			.download-expired
			{
				display: flex;
				margin-top: size(16);
				width: 100%;
				justify-content: flex-end;
				color: color('inactive');
				span
				{
					@extend .caption;
					@extend .bold;
				}
			}
			div
			{
				@extend .bold;
				span
				{
					@extend .caption;
				}
				.label
				{
					color: color('subtext');
					margin-right: size(8, 12);
				}
				.expired-label
				{
					margin-left: size(4, 12);
					color: color('error');
				}
			}

		}
	}
	.description
	{
		color: color('subtext');
		margin-bottom: size(30);
	}
}