@import 'src/css/General.scss';

.modal
{
	.title
	{
		font-size: rem(17);
		font-weight: bold;
		text-align: center;
		margin-bottom: rem(24)!important;
		margin-top: 0!important;
		line-height: 1!important;
	}
	.description
	{
		font-weight: bold!important;
		text-align: left!important;
		font-size: rem(14)!important;
		margin-bottom: rem(24)!important;
		color: color('text')!important;
    white-space: pre-wrap;

		b
		{
			color: color('text')!important;
			font-weight: bold;
		}
	}

  .note-title
  {
    font-size: rem(12);
    font-weight: bold;
    color: color('subtext');
    margin-bottom: 0.4em;
    text-align: left;
  }
.notes
	{
		li
		{
			display: flex;
			text-align: left;
			font-size: rem(12);
			font-weight: bold;
			color: color('subtext');
			span
			{
				text-align: left;
			}
		}
	}
	.image
	{
		width: rem(226);
		margin-bottom: rem(8)!important;
	}

}