@import '/src/css/General.scss';

.FAQPage
{
	.title
	{
		@extend .h1;
		margin-bottom: size(40, 24);
	}
	.block{
		margin-bottom: size(60);
		.items
		{
			margin-top: size(20);
			.item
			{
				border: 1px solid color('border2');
				border-radius: size(16);
				padding: size(32) size(20);
				margin-bottom: size(12);
				.header
				{
					display: flex;
					align-items: flex-start;
					height: auto;
					.q
					{
						@extend .bold;
						white-space: pre-wrap;
						flex: 1;
					}
	
					.icon
					{
						font-size: size(24);
						transform: translateY(-#{size(4)}) rotate(90deg);
						transition: transform 0.3s;
						margin-bottom: -1em;
					}
				}
				.content
				{
					height: 0;
					overflow: hidden;
					transition: height 0.3s;
					.a
					{
						// @extend .bold;
						padding-top: size(32);
						display: block;
						white-space: pre-wrap;
						a
						{
							text-decoration: underline;
						}
						img
						{
							width: 100%;
							height: auto;
							margin-top: size(30);
							margin-bottom: size(30);
						}
						table{
							th{
								@extend .bold;
								text-align: left;
								min-width: size(64);
							}
						}
					}
					.round-button
					{
						margin-top: size(23);
						margin-bottom: 0!important;
					}
				}
	
				&.open
				{
					.header
					{
						.icon
						{
							transform: translateY(-#{size(4)}) rotate(90deg) scaleX(-1);
						}
					}
				}
			}
		}
	}

	.unsolved
	{
		.description
		{
			margin-bottom: size(24);
		}
	}
}