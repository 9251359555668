@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.HomeCTAPage {
	// padding-top: size(110) !important;
	.title {
		@extend .bold;
		color: color('primary');
		text-align: center;
		.leftbar, .rightbar
		{
			border-left: 2px solid color(primary);
			height: size(22);
			display: inline-block;
			transform-origin: bottom;
		}
		.leftbar
		{
			transform: translateX(-#{size(10)}) translateY(size(2)) rotate(-30deg);
		}
		.rightbar
		{
			transform: translateX(#{size(10)}) translateY(size(2)) rotate(30deg);
		}
	}
	.description
	{
		@extend .h3;
		color: color('subtext');
		text-align: center;
		margin-top: size(24, 17);
		margin-bottom: size(3, 17);
		&::before, &::after
		{
			content: '';
			border-left: 2px solid color(subtext);
			height: size(20);
			display: inline-block;
			transform-origin: bottom;
		}
		&::before
		{
			transform: translateX(-#{size(10)}) translateY(size(2)) rotate(-30deg);
		}
		&::after
		{
			transform: translateX(#{size(10)}) translateY(size(2)) rotate(30deg);
		}


	}
	.sized-video
	{
		width: min(768px, 100vw);
	}
	.buttons
	{
		@extend .padding;
	}
}