@import 'src/css/General.scss';

.CreateAccountPage
{

	.message
	{
		margin-bottom: size(32);
	}
  .mail-magazine{
		margin-top: size(32);
		margin-bottom: size(12);
  }
	.terms
	{
		margin-bottom: size(32);
		a
		{
			text-decoration: underline;
		}
		li
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			display: flex;
			align-items: flex-start;
			&>
			{
				text-align: left;
				align-items: flex-start;
			}
		}
		a[href='/ja/contact/']{
			i{
				display: none;
			}
		}

	}

	.register-email-sent
	{
		.notes
		{
			margin-top: size(32);
			margin-bottom: size(50);
			a
			{
				text-decoration: underline;
			}
			li
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
				display: flex;
				align-items: flex-start;
				&>
				{
					text-align: left;
					align-items: flex-start;
				}
			}

			a[href='/ja/contact/']{
				i{
					display: none;
				}
			}
		}

		.fromLPCoverImage{
			width: calc(100% + (1.6em * 2));
			margin: 0 -1.6em;
			image-rendering: -webkit-optimize-contrast;
		}
	}
}