@import 'src/css/General.scss';

.modal
{
  .title
  {
    font-size: rem(17);
    font-weight: bold;
    color: color('text');
    margin-bottom: rem(16);
  }

  .description
  {
    font-size: rem(12);
    font-weight: bold;
    color: color('subtext');
    margin-bottom: rem(24);
  }

  .buttons
  {
  }
}

.Recorder
{
	background-color: color('text');
	min-height: 100vh !important;
	overflow-y: visible;

	.help-bubble
	{
		position: absolute;
		white-space: pre;
		color: color('text');
		font-size: size(10);
		font-weight: bold;
		bottom: 100%;
		margin-bottom: size(14);
		left: 0;
		z-index: 10000;
		[class="bubble-round-tip"]
		{
			left: size(85);
		}
	}

	.close-btn
	{
		color: color('white');
		position: absolute;
		top: size(20);
		left: size(24);
		z-index: 10;
		[class*=icon]
		{
			font-size: size(24);
		}
	}

	.wrapper
	{
		// padding-bottom: size(64);
		
	}
	.preview
	{
		z-index: 100;
		position: relative;
		[class*=video]
		{
			width: min(768px, 100vw);
			height: min(768px, 100vw);
			transform: rotateY(180deg);
		}
		&.previewing [class*=video], &.back [class*=video]
		{
			transform: rotateY(0deg);
		}

		.ui
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			.play-icon
			{
				position: absolute;
				width: size(75);
				height: auto;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}

			.countdown
			{
				position: absolute;
				font-size: size(64);
				width: 38%;
				height: 38%;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
			}

			.guide
			{
				display: flex;
				flex-direction: column;
				align-items:center;
				position: absolute;
				top: size(47);
				left: 50%;
				transform: translateX(-50%);
				width: 100%;
				bottom: 0;
				// .guide-face
				// {
				// 	width: size(178);
				// 	height: size(178);
				// 	transform: scaleX(0.871);
				// 	border-radius: size(148);
				// 	border: dashed size(7) color('white');
				// }

				.bubble
				{
					// margin-top: size(20);
					font-size: size(12);
					font-weight: bold;
					top: size(200, 12);
					position: absolute;
					// width: size(311, 12);
					white-space: pre-wrap;
					i
					{
						font-size: 1em;
					}
				}
			}
		}
	}
	.action
	{
		padding: size(24) size(24) size(0) size(24);
		// min-height: size(150);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		.round-button
		{
			margin-bottom: 0;
		}
		.speech-bubble
		{
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: size(12);
			.small
			{
				.item
				{
					font-size: size(12);
					font-weight: bold;
					display: flex;
					align-items: center;
					justify-content: center;
					*
					{
						display: inline;
					}
					.icon
					{
						width: size(12, 12);
						height: size(12, 12);
						margin-right: size(6, 12);
					}
				}
			}
		}
	}
	.buttons
	{
		display: flex;
		// flex-direction: row-reverse;
		padding: size(12) size(24);

		&>button{
			margin: 0 size(4);
		}
	}
	.stop-button
	{
		@extend .bold;
		margin-top: size(30, 12);
		margin-bottom: size(24, 12);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: size(12);
		color: color('subtext');
		text-align: center;
		i
		{
			margin-right: size(8);
		}
	}

}

.record-buttons
{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	color: white;
	padding: size(24);
	&>*
	{
		display: flex;
		flex-direction: column;
		align-items: center;


		.label
		{
			font-size: size(12);
			font-weight: bold;
			margin-top: size(9, 12);
      height: 2em;
		}

		&.side-button
		{
			width: size(80);
			.side-icon
			{
				font-size: size(24);
			}
			.label
			{
				margin-top: size(4, 12);
				text-align: center;
				min-height: 3em;
			}
		}


	}

	.rec-button
	{
		// width: 100%;
		flex-grow: 1;
	}
}

.selectVideosActions{
	width: 100%;
	display: flex;
	.record-buttons{
		padding: 0;
		justify-content: space-evenly;

		.side-button{
			.side-icon
			{
				font-size: size(32);
			}
			.label{
				margin: 0;
				font-size: size(10);
				color: color('subtext');
				min-height: 0;
			}
		}
	}
}