@import '/src/css/General.scss';

.expired-movie
{
	.content
	{
		text-align: center;
		margin-top: size(136);
		margin-bottom: 2em;
		.title
		{
			@extend .h2;
			margin-top: 0.6em;
		}
	}
}