@import '/src/css//General.scss';

@keyframes view-movie-bubble {
	0%{
		transform: translateY(calc(50vh));
		opacity: 0;
	}
	20%{
		transform: translateY(calc(50vh - 100%));
		opacity: 1;
	}
	75%{
		transform: translateY(calc(50vh - 100%));
		opacity: 1;
	}
	100%{
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes view-movie-content {
	0%{
		opacity: 0;
	}
	70%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

.ViewMoviePage-app,
.PreviewViewPage-app{
	.App{
		padding: 0;
	}
}

.presented_by
{
  width: 100%;
  margin-top: rem(37);
  text-align: center;
  img
  {
    width: rem(96);
  }
}

.ViewMoviePage,
.PreviewViewPage
{
	min-height: 100vh;
	background-color: color('white2');
	.view-movie
	{
		background-size: 100%;
		width: 100%;
		background-position: top;
		padding: size(24);
		height: 100vh;
		// min-height: 772px;
		display: flex;
		flex-direction: row;
		background-repeat: no-repeat;
		background-color: color('white_bg');
		flex-direction: column;
		justify-content: flex-start;

		&.gradation{
			background: linear-gradient(180deg, color('gradation_bg') 50%, color('white_bg') 100%);
		}

		.bubble
		{
			text-align: center;
			margin-bottom: size(27);
			.speech-bubble
			{
				display: inline-block;
				background-color: color('white');
				color: color('title');
				
				filter: drop-shadow(0px 14px 8px rgba(0, 0, 0, 0.05));

				.arrow
				{
					border-top-color: color('white');
				}
			}
			
		}
		.password-label
		{
			text-align: center;
			margin-bottom: size(0);
			color: color('themetext');
		}
	
		.password-input
		{
			padding-top: 160px;
			justify-self: center;
			align-self: center;
			display: flex;
			height: 100%;
			align-items: center;
			width: 100%;
			justify-content: space-between;
			flex-direction: column;

			.wrapper
			{
				width: 100%;
				.code-input-field
				{
					input
					{
						background-color: color('white');
					}
				}
			}
		}
	}

	.note
	{
		@extend .caption;
		@extend .bold;
		text-align: center;
		color: color('themesubtext');
		margin-top: size(32);
	}

	.movie-content
	{

		width: 100%;
		.logo-animation
		{
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: size(89);
			height: size(89);
			transition: opacity 0.3s;
		}

		&.loaded
		{
			.logo-animation
			{
				opacity: 0;
			}

			.movie
			{
				.bubble
				{
					z-index: 60000;
					animation: view-movie-bubble 1.3s forwards;
				}
				.video-container, .buttons, .note
				{
					animation: view-movie-content 1.6s forwards;
				}
			}
		}


		.movie
		{
			padding-top: size(11);
			.bubble
			{
				opacity: 0;
			}
			.video-container
			{
				opacity: 0;
				transition: opacity 0.3s 2s;
				width: size(327);
				height: size(327);
				margin-top: size(40);
				margin-bottom: size(32);
				margin-left: auto;
				margin-right: auto;
				.video
				{
					width: size(327);
					height: size(327);
					// border-radius: size(16);
					// overflow: hidden;
					video
					{
						border: 2px solid color('alt');
						border-radius: size(16);
					}
					.video-poster
					{
						border: 2px solid color('alt');
						border-radius: size(16);
					}
				}
				
				.play-icon
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
					&.hide
					{
						display: none;
					}
				}
			}


			.buttons
			{
				opacity: 0;
				transition: opacity 0.3s 2s;
				padding: 0 size(38);
				margin-bottom: size(32);
			}

			.note
			{
				opacity: 0;
				transition: opacity 0.3s 2s;
				@extend .caption;
				@extend .bold;
				text-align: center;
				color: color('themesubtext');
				margin-bottom: 0;
			}

		}

	}

}