@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.TermsPage
{
	white-space: pre-wrap;
	.description
	{
		// @extend .bold;
		margin-top: size(32);
	}
	.indexes
	{
		@extend .full-width;
		@extend .padding;
		background-color: color('white2');
		margin-top: size(46);
		padding-top: size(36);
		padding-bottom: size(54);
		h1
		{
			margin-top: 0;
			margin-bottom: size(24, 24);
		}
		li{
			margin-bottom: size(15);
			a
			{
				@extend .bold;
				font-size: size(15);
				display: flex;
				align-items: flex-start;

				.icon
				{
					// transform: rotate(90deg);
					color: color('subtext');
					margin-left: 0.5em;
				}
			}
		}
	}

	ol.topics
	{
		margin-top: size(30);
		// @extend .bold;
		ol
		{
			display: block;
			flex-basis: 100%;
		}
		li
		{
			flex-wrap: wrap;
			flex-basis: 100%;
			display: flex;
			justify-content: left;
			&::before
			{
				content: attr(data-index);
				text-align: left;
			}
			&>.text
			{
				flex: 1;
				white-space: pre-wrap;
				a
				{
					display: inline;
					word-break: break-all;
					vertical-align: baseline;
					.icon
					{
						top: 0.1em;
						color: color('subtext');
					}
				}
			}
			&.comment
			{
				padding: size(36) size(24);
				background-color: color('white2');
				border-radius: size(16);
			}
		}
		li.no-bullet
		{
			&::before
			{
				content: '';
				min-width: 0;
				display: none;
			}
		}
		&>li
		{
			display: block;
			margin-top: size(30);
			margin-bottom: size(45);
			&::before
			{
				@extend .h2;
				content: attr(data-index);
				display: inline;
			}
			&>.text
			{
				@extend .h2;
				display: inline;
			}

			.indent-1
			{
				display: block;
				margin-top: size(25);
				&>li
				{
					margin-bottom: size(30);
					&::before
					{
						min-width: size(24);
					}
					&>.text
					{
					}
				}
			}
			.indent-2
			{
				margin-left: size(24);
				margin-top: size(30);
				&>li
				{
					margin-bottom: size(26);
					&::before
					{
						content: attr(data-index)!important;
						min-width: size(30)!important;
						text-align: center;
						display: inline-block!important;
					}
				}
			}
		}
	}

	&.page-privacy
	{
		.indent-2
		{
			margin-top: size(16) !important;
			&>li
			{
				margin-bottom: 0!important;
			}
		}
	}
}