@import '/src/css/General.scss';
.PurchaseMoviePage
{

	.description
	{
		@extend .text;
		margin-top: size(10, 15);
		margin-bottom: size(24, 15);
	}
	.radio
	{
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		margin-bottom: size(24);

		.label
		{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			.caption
			{
				font-size: size(11);
				color: color('subtext');
				margin-bottom: size(11);
			}
			.title
			{
				font-size: size(15);
				margin-bottom: size(13);
			}
			.price
			{
				font-size: size(17);
				&[class*="free"]:not(.free_res)
				{
					color: color('primary');
				}

				.tax
				{
					margin-left: size(4);
					font-size: size(11);
					color: color('subtext');

				}

				.hidden
				{
					display: none;
				}
			}

			.description
			{
				margin: 0;
				margin-bottom: size(12);
				color: color('ok');
				font-size: size(11);
			}

			ul
			{
				li
				{
					text-align: left;
					margin-bottom: size(8);
					display: flex;
					align-items: center;
					.check-icon
					{
						background-color: color('ok');
						color: color('white');
						display: inline-block;
						width: size(20);
						height: size(20);
						border-radius: size(20);
						display: inline-flex;
						align-items: center;
						justify-content: center;

						&.disabled
						{
							background-color: color('border');
						}
						.icon
						{
							margin-left: 0.1em;
						}
						margin-right: size(6);
					}
					span
					{
						@extend .caption;
						@extend .bold;
						color: color('subtext');
						display: inline-flex;
						align-items: center;
						margin-bottom: 0;

						.icon
						{
							font-size: 1.4em;
							margin-left: 0.2em;
						}
					}
					.info-button
					{
						color: color('subtext');
						font-size: rem(18);
						margin-left: rem(2);
						margin-top: -0.5em;
						margin-bottom: -0.5em;
					}
		
				}
			}

		}

		.right
		{
			text-align: right;
			align-self: flex-end;
		}
	}

}