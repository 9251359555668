@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	white-space: pre-wrap;
	padding-bottom: size(80);

	.intro-video
	{
		width: size(327);
		height: size(327);
		margin-left: size(-8);
		margin-right: size(-8);
		border-radius: size(40);
		overflow: hidden;
		margin-bottom: size(20);
		video
		{
			border-radius: size(40);
		}
	}
	.title
	{
		font-size: size(32);
		font-weight: 900;
		line-height: 1.4em;
		margin: size(20, 32) 0;
    [class='en'] &
    {
      font-size: size(28);
    }
	}
	.link-list
	{
		display: flex;
		justify-content: center;
		font-size: size(12);
		color: color('subtext');
		font-weight: bold;
		a
		{
			display: flex;
			align-items: center;
			margin-left: 0.7em;
			&:first-child
			{
				margin-left: 0;
			}
			.icon
			{
				font-size: 1.4em;
				margin-top: 0.1em;

			}
		}
	}
	.description
	{
		color: color('text');
		font-size: size(24);
		font-weight: bold;
		margin-top: size(40, 24);
		margin-bottom: size(32, 24);
	}

	ul.items
	{
		li
		{
			margin-bottom: size(40);
			&:last-child
			{
				margin-bottom: 0;
			}
			img
			{
				border-radius: size(16);
				width: 100%;
				height: auto;
				margin-bottom: size(16);
			}
			.caption
			{
				font-size: size(17);
				font-weight: bold;
			}
		}
	}
}