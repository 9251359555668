@import 'src/css/General.scss';

.MovieDashboardPage
{
	.modal
	{

		.price
		{
			display: flex;
			width: 100%;
			align-items: center;
			margin-bottom: size(16);
			.desc b
			{
				@extend .bold;
			}
			.value
			{
				@extend .bold;
			}
			.separator{
				height: 1px;
				border-top: color('subtext') dashed 1px;
				flex-grow: 1;
				margin-left: size(10);
				margin-right: size(10);
			}
		}

		.inviteContent
		{
			h2
			{
				text-align: center;
			}
		}
	}

	.sample-button
	{
		font-size: 1em;
		display: flex;
		align-items: center;
		border: 1px solid color('border');
		border-radius: size(16);
		padding: size(11) size(15);
		width: 100%;

		.thumb
		{
			display: block;;
			border-radius: size(8);
			overflow: hidden;
			width: size(52);
			height: size(52);
			img
			{
				width: 100%;
				height: 100%;
			}
		}

		.label
		{
			flex: 1;
			display: flex;
			flex-direction: column;
			margin: 0 size(12);
			align-items: flex-start;
			.description
			{
				@extend .caption;
				@extend .bold;
				color: color('gray');
			}
			.title
			{
				@extend .bold;
			}
		}

		.arrow
		{
			flex: 0;
		}
	}

	.sample-modal
	{
		.thumbs
		{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			.thumb
			{
				font-weight: 1;
				margin: size(16) 0;
				.img
				{
					width: size(134);
					height: size(134);
					border-radius: size(12);
					overflow: hidden;
					margin-bottom: size(12);
					img
					{
						width: 100%;
						height: 100%;
					}
				}
				.label
				{
					@extend .bold;
					text-align: center;
					color: color('subtext');
				}
			}
		}
	}

	.limit
	{
		@extend .bold;
		font-size: size(12);
		color: color('error');
		text-align: center;
		white-space: pre-wrap;
		margin-bottom: size(36, 12);
	}

	.first-time
	{
		.modal-container{
			// width: size(345);
		}
		.title
		{
			display: flex;
			justify-content: center;
			margin-bottom: size(20);
			svg
			{
				width: size(182, 20);
				height: auto;
			}
		}

		.instruction
		{
			text-align: center;
			img
			{
				width: size(300);
				height: auto;
				// display: block;
			}
			.text
			{
				// @extend .bold;
				margin-top: size(30);
				white-space: pre-wrap;
				text-align: left;
			}
		}
		.note
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			white-space: pre-wrap;
			a
			{
				display: inline;
				text-decoration: underline;
			}
		}

		.buttons
		{
			margin-top: size(30);
		}
	}

	.panel
	{
		background-color: color('white2');
		border-radius: size(16);
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: size(50) size(24) size(32);
		margin-bottom: size(32);
		.edit
		{
			position: absolute;
			top: 0;
			right: 0;
			font-size: size(16);
			color: color('subtext');
			margin: 0;
			padding: size(20, 16);
		}
		&>*
		{
			margin: size(6) 0;
		}
		.subtitle
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
		}
		.title
		{
			@extend .h3;
			white-space: pre-wrap;
			word-break: break-all;
		}
		.separator
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
		}
		.description
		{
			@extend .caption;
			@extend .bold;
		}

		.invite-link
		{
			@extend .caption;
			@extend .bold;
			margin-top: size(12, 12);
			color: color('primary');
		}
	}
	.movieId
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		display: flex;
		align-items: center;
		justify-content: center;

		.icon
		{
			margin-left: size(4, 16);
			font-size: size(16, 12);
		}
	}
	.purchased
	{
		text-align: center;
		.title
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
		}
	}
	.info
	{
		margin-top: size(45);
		margin-bottom: size(70);
		display: flex;
		
		justify-content: stretch;
		.item
		{
			flex: 1;
			border-left: 1px solid color('border');
			text-align: center;
			&:first-child
			{
				border-left: none;
			}
			.title
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
				margin-bottom: size(16, 12);
				display: inline-flex;
				align-items: center;
				.icon
				{
					margin-left: size(4, 12);
				}
			}
			.description
			{
				@extend .h3;
				margin-bottom: size(16, 17);
			}

			.bottom
			{
				position: absolute;
				top: 100%;
				width: 100%;
				text-align: center;
				.extend
				{
					@extend .caption;
					@extend .bold;
					color: color('primary');
				}
			}
		}
	}
	.participants
	{
		.head
		{
			margin-bottom: size(20);
			display: flex;
			justify-content: space-between;
			.title
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
			}
			.reorder-button
			{
				@extend .caption;
				@extend .bold;
				color: color('primary');

				&.disabled
				{
					color: color('inactive');
					pointer-events: none;
				}
			}
		}
		.participants
		{
			a
			{
				display: block;
			}
			li
			{
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: size(16);
				.index
				{
					@extend .bold;
					display: inline-block;
					font-size: size(11);
					color: color('gray');
					border-radius: size(36, 11);
					background-color: color('white2');
					width: size(52, 11);
					text-align: center;
					padding: size(10, 11);
					margin-right: size(10, 11);
					word-break: keep-all;
				}
				.name
				{
					@extend .bold;
				}
				.you
				{
					@extend .bold;
					color: color('subtext');
					margin-left: size(4);
				}
				.arrow
				{
					flex: 1;
					text-align: right;
					font-size: 1.2em;
					align-self: center;
				}
			}
		}
	}
}