@import '/src/css/General.scss';

section.HomeFAQPage
{
	padding: 0!important;
}
.HomeFAQPage
{
	padding-top: size(40)!important;
	padding-bottom: size(75)!important;
	background-color: color('white');
	// &.inverted
	// {
	// 	background-color: color('white2');
	// 	.items
	// 	{
	// 		.item
	// 		{
	// 			background-color: color('white');

	// 		}
	// 	}
	// }
	.title
	{
		@extend .h2;
		text-align: center;
		margin-top: 0;
		.icon
		{
			font-size: size(22, 20);
			margin-bottom: size(5, 20);
			color: color('primary');
		}
	}
	.items
	{
		margin-top: size(40);
		margin-bottom: size(40);
		.item
		{
			background-color: color('white2');
			border: 1px solid color('border2');
			border-radius: size(16);
			padding: size(32) size(20);
			margin-bottom: size(12);
			.header
			{
				display: flex;
				align-items: flex-start;
				height: auto;
				.q
				{
					@extend .bold;
					white-space: pre-wrap;
					flex: 1;
				}

				.icon
				{
					font-size: size(24);
					transform: translateY(-#{size(4)}) rotate(90deg);
					transition: transform 0.3s;
					margin-bottom: -1em;
				}
			}
			.content
			{
				height: 0;
				overflow: hidden;
				transition: height 0.3s;
				.a
				{
					// @extend .bold;
					padding-top: size(32);
					display: block;
					white-space: pre-wrap;
					a
					{
						text-decoration: underline;
					}
				}
				.round-button
				{
					margin-top: size(23);
					margin-bottom: 0!important;
				}
			}

			&.open
			{
				.header
				{
					.icon
					{
						transform: translateY(-#{size(4)}) rotate(90deg) scaleX(-1);
					}
				}
			}
		}
	}

	.buttons
	{

	}
}