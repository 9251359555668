@import 'src/css/General.scss';

.title
{
	margin-top: size(40, 17);
	margin-bottom: size(22, 17);
}

.description
{
	margin-top: size(-6);
	margin-bottom: size(16);

	i
	{
		color: color('primary');
		font-style: normal;
	}
}