@import 'src/css/General.scss';

#root.NewMovieDashboardPage-app {
	background-color: color('white2')!important;
	position: relative;

	.App
	{
		background-color: color('white2') !important;
	}

	header
	{
		@include breakpoint('small')
		{
			background-color: color('white2') !important;
		}
	}

	&.plus{
		.confetti_dashboard{
			display: block !important;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 25em;
		}

		.App{
			background: none !important;
			.NewMovieDashboardPage{
				background: none !important;
			}
		}
		header{
			background: none !important;
		}
	}
}

.NewMovieDashboardPage
{
	background-color: color('white2') !important;
	.info
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: size(40);
		.preview
		{
			width: size(131);
			height: size(131);
			display: block;
			overflow: hidden;
			border-radius: size(12);
			background-color: color('white');

			.preview-image
			{
				width: 100%;
				height: 100%;
				border-radius: size(12);
				img
				{
					width: 100%;
					height: auto;
				}
			}
			.play-icon
			{
				position: absolute;
				width: size(43);
				height: size(43);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		h2
		{
			margin-top: size(24, 20);
			margin-bottom: size(20, 20);
			.icon
			{
				font-size: 0.8em;
				margin-left: 0.2em;
				color: color('subtext');
			}
		}

		.expires-label
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			margin-bottom: size(12, 12);
		}

		.expires-date
		{
			a
			{
				display: block;
			}
			display: inline;
			white-space: pre-wrap;
			text-align: center;
			.icon
			{
				display: inline;
				font-size: 0.95em;
				margin-left: 0.2em;
				margin-bottom: -0.1em;
				color: color('subtext');
			}
		}
	}

	.top-buttons
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: size(40);
		&>*
		{
			width: calc(33% - 13px / 3);
			[class*=label]
			{
				height: 2.3em;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.participants
	{
		.head
		{
			margin-bottom: size(20);
			display: flex;
			justify-content: space-between;
			.title
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');

				&.maxed
				{
					b
					{
						color: color('primary');	
					}
				}

			}
			.reorder-button
			{
				@extend .caption;
				@extend .bold;
				color: color('primary');

				&.disabled
				{
					color: color('inactive');
					pointer-events: none;
				}
			}
		}
		.participants
		{
			a
			{
				display: block;
			}
			li
			{
				display: flex;
				width: 100%;
				align-items: center;
				margin-bottom: size(8);
				background-color: color('white');
				border-radius: size(16);
				padding: size(8) size(8) size(8) size(16);

				&>a, &>button
				{
					display: flex;
					width: 100%;
				}

				&.invite
				{
					color: color('primary');
					border: 1px dashed color('primary');
					background-color: color('white');
					&.disabled
					{
						color: color('inactive');
						border-color: color('inactive');
					}
				}

				.thumb
				{
					width: size(40);
					height: size(40);
					border-radius: 2em;
					overflow: hidden;
					margin-right: size(8);
					display: flex;
					align-items: center;
					justify-content: center;
					.icon
					{
						font-size: size(25);
					}

					img
					{
						width: 100%;
						height: auto;
					}

				}
				.name
				{
					@extend .bold;
				}
				.you
				{
					@extend .bold;
					color: color('subtext');
					margin-left: size(4);
				}
				.arrow
				{
					flex: 1;
					text-align: right;
					font-size: 1.2em;
					align-self: center;
				}
			}
		}
	}

	.slideshowBtn
	{
		color: color('white');
		border-color: color('subtext');
		background-color: color('subtext')!important;
		.lockbanner
		{
			display: none;
		}
		&.disabled
		{
			color: color('white');
			border-color: color('subtext');
			background-color: color('subtext')!important;
			.lockbanner
			{
				display: block;
				position: absolute;
				top: -0.6em;
				right: 1.4em;
			}
		}
	}

	.banners
	{
		.banner
		{
			margin-top: size(40);
			width: 100%;
			img
			{
				overflow: hidden;
				border-radius: size(16);
					width: 100%;
			}
		}
	}
	.panels
	{
		margin-top: size(40);
		margin-bottom: size(40);
		.info-panel
		{
			display: flex;
			border: 1px solid color('border');
			border-radius: size(16);
			padding: size(24) size(21) size(21);
			text-align: center;
			flex-direction: column;
			margin-bottom: size(14);

			.disabled
			{
				pointer-events: all;
			}
	
			.caption
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
				display: flex;
				align-items: center;
				margin-bottom: size(14, 12);
				.icon
				{
					font-size: 1.2em;
					margin-left: 0.2em;
					line-height: 1em;
				}
			}
	
			.description
			{
				@extend .bold;
				display: flex;
				align-items: center;
				.link
				{
					@extend .caption;
					margin: 0;
					display: flex;
					align-items: center;
					color: color('primary');
					margin-left: 0.4em;
				}
			}
		}

	}

	.remove{
		@extend .caption;
		@extend .bold;
		color: color('error');
		text-align: center;
		width: 100%;
		justify-content: center;

		.icon
		{
			margin-left: 0.2em;
		}
	}

	.modal
	{
		form
		{
			.check-box
			{
				justify-content: center;
			}
		}

		.price
		{
			display: flex;
			width: 100%;
			align-items: center;
			margin-bottom: size(16);
			.text
			{
				margin-bottom: 0;
				font-size: 1em;
			}
			.desc b
			{
				@extend .bold;
			}
			.value
			{
				@extend .bold;
			}
			.separator{
				height: 1px;
				border-top: color('subtext') dashed 1px;
				flex-grow: 1;
				margin-left: size(10);
				margin-right: size(10);
			}
		}

	}

	.invite-modal
	{
		h3
		{
			text-align: center;
		}
	}

	.share_coupon-modal{
		.title{
			font-size: 20px !important;
			margin-bottom: 24px;
			font-weight: 700;
		}
		.headline{
			color: color('subtext');
			font-weight: 700;
			font-size: 12px;
		}

		.items{
			display: flex;
			justify-content: space-between;
			margin: 14px 0 16px;

			.item{
				width: calc(50% - 5px);
				padding: 0 8px;
				background-color: color('white2');
				border-radius: 16px;

				display: flex;
				flex-direction: column;
    		align-items: center;

				.count{
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 17px;
					width: 27px;
					height: 27px;
					border-radius: 50%;
					background-color: color('alt');
					color: white;
					font-family: 'lato';
					font-weight: 700;
				}

				.itemtitle{
					margin-top: 6px;
					font-size: 12px;
					font-weight: 700;
				}

				.image{
					margin: 0;
					padding: 12px 23px;

					img{
						height: 76px;
						object-fit: contain;
						margin: 0 !important;
					}
				}

				.text{
					font-size: 10px;
					font-weight: 700;
					margin-bottom: 18px;
				}
			}
		}

		.enddate{
			margin-top: 12px;
			padding: 13.5px;
			border: 1px solid color('border2');
			border-radius: 8px;

			font-size: 16px;
			font-weight: 700;
		}

		.description{
			margin-top: 24px;
			margin-bottom: 0;
		}
	}
}


.extend-modal
{
	.text
	{
		color: color('text')!important;
		font-size: rem(15);
	}

	.value
	{
		font-size: rem(17);
		font-family: lato;
	}

	.tax
	{
		margin-left: rem(4);
		color: color('subtext');
		font-size: rem(12);
		font-weight: bold;
	}
}