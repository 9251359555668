@import 'src/css/General.scss';

.panels
{
	display: flex;
	justify-content: space-between;
}
.panel
{
	flex-grow: 0;
	width: size(148);
	border-radius: size(16);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: color('white2');
	padding: size(16);
	padding-bottom: size(23);

	.head
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: size(4);
		.index
		{
			font-weight: bold;
			font-size: size(16);
			display: flex;
			width: size(27, 16);
			height: size(27, 16);
			color: color('white');
			background-color: color('secondary');
			border-radius: 2em;
			align-items: center;
			justify-content: center;
		}

		.label
		{
			color: color('secondary');
			font-size: size(12);
			font-weight: bold;
		}
	}

	.thumb
	{
		overflow: hidden;
		width: size(116);
		height: size(116);
		border-radius: size(12);
		margin: size(12) auto;
		video
		{
			width: 100%;
			height: auto;
			overflow: hidden;
			border-radius: size(12);
		}
	}

	.caption
	{
		color: color('text');
		font-size: size(12);
		font-weight: bold;
		b
		{
			color: color('primary');
		}
	}
}
