@import 'src/css/General.scss';

header.header
{
	position: fixed;
	top: 0;
	height: size(64);
	display: flex;;
	flex-direction: horizontal;
	justify-content: space-between;
	align-items: center;
	padding: 0 size(24);
	z-index: 100;
	background-color: inherit;
	width: 100%;
	left: 0;
	background-color: color('white_bg');

	&.not-fixed
	{
		position: absolute;
    top: 0!important;
	}

	@include breakpoint('small')
	{
		background-color: color('white');
		// left: calc(calc(100vw - min(640px, 100vw)) * 0.5);
		// width: min(640px, 100vw);
	}

	.standalone &
	{
		display: none;
	}

	.toasts
	{
		z-index: 90;
		position: fixed;
		top: 0;
		// bottom: size(28);
		left: 0;
		right: 0;
	}	
}