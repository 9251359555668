@import 'src/css/General.scss';

.ResetPasswordPage
{
	.message
	{
		margin-bottom: size(32);
		white-space: pre-wrap;
	}
	.reset-password-sent
	{
		.notes
		{
			margin-top: size(32);
			margin-bottom: size(50);
			a
			{
				text-decoration: underline;
			}
			li
			{
				@extend .caption;
				@extend .bold;
        .bullet
        {
          white-space: pre;
        }
				color: color('subtext');
				display: flex;
				align-items: flex-start;
				&>
				{
					text-align: left;
					align-items: flex-start;
				}
			}
		}
	}
	
}