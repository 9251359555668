@import 'src/css/General.scss';

.RecordMoviePage
{
	.header
	{
		.description
		{
			font-size: size(15);
			margin-bottom: size(25, 15);
		}
	}

	.modal
	{
		.modal-container
		{
			text-align: center;
		}
		img
		{
			width: 100%;
			height: auto;
			margin: size(20) auto 0;
		}

		.title
		{
			@extend .h2;
			margin: 0;
			margin-bottom: size(16, 20);
		}

		.description
		{
			@extend .bold;
			font-size: size(15);
			color: color('black');
			// margin-top: size(26, 15);
			margin-bottom: size(12, 15);
			b
			{
				color: color('primary');
			}
		}

		.text
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			margin: 0;
		}

		.buttons
		{
			text-align: center;
			margin-top: size(24);
			.already-recorded
			{
				@extend .bold;
				@extend .caption;
				display: flex;
				align-items: center;
				justify-content: center;
				color: color('subtext');
				text-align: center;
				margin-top: size(20);
				i
				{
					font-size: size(18, 15);
					margin-right: size(2);
				}
			}
			.stop-button
			{
				@extend .bold;
				font-size: size(12);
				display: flex;
				align-items: center;
				justify-content: center;
				color: color('subtext');
				text-align: center;
				margin-top: size(20, 12);
				i
				{
					font-size: size(14, 12);
					margin-right: size(4, 12);
				}
			}
		}

		&.modal-1-2
		{
			img
			{
				width: size(250);
				height: auto;
				align-self: center;
			}
		}

		&.modal-2-1
		{
			img
			{
				width: size(176);
				height: auto;
				align-self: center;
			}
		}

		&.modal-3-1
		{
			.thumbs
			{
				display: flex;
				margin-top: size(32);
				.thumb
				{
					margin: size(4);
					flex: 1;
					>.label
					{
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: size(12);
						.index
						{
							font-family: 'lato';
							font-size: size(13);
							display: inline-block;
							width: size(21, 13);
							height: size(21, 13);
							line-height: size(21, 13);
							background-color: color('secondary');
							color: color('white');
							border-radius: 2em;
							text-align: center;
						}
						.text
						{
							@extend .bold;
							color: color('text');
							margin: 0 size(5);
						}
					}
					.preview
					{
						display: block;
						width: size(153);
						height: size(153);
						border-radius: size(4);
						overflow: hidden;
					}
					.round-button
					{
						min-height: size(36);
						.label
						{
							font-size: size(12);
						}
					}
				}
			}

		}

		&.cancel-modal
		{
			.title
			{
				@extend .h3;
			}
			.description
			{
				@extend .caption;
				@extend .bold;
				color: color('subtext');
				margin-bottom: size(30, 12);
			}
		}
	}

	.steps
	{
		.title
		{
			@extend .bold;
			font-size: size(11);
		}
		ul
		{
			margin-top: size(12);
			margin-bottom: size(46);
			li
			{
				display: flex;
				align-items: center;
				margin-bottom: size(16);
				img
				{
					width: size(70);
					height: size(70);
					border-radius: size(13);
					margin-right: size(16);
				}
				.info
				{
					.label
					{
						@extend .caption;
						@extend .bold;
						color: color('secondary');
					}
					.description
					{
						@extend .bold;
						margin-top: size(6);
					}
				}
			}
		}
	}	
	.sample-video
	{
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		pointer-events: all;
		.video
		{
			width: min(768px, 100vw);
			height: min(768px, 100vw);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
		.closeBtn
		{
			position: absolute;
			// top: calc(50% + 55vw);
			// bottom: 10vh;
			bottom: 10vh;
			left: 50%;
			transform: translateX(-50%);
			width: size(57);
			height: size(57);
		}
	}

	form
	{
		margin-top: size(70);
		text-align: left;
	}

	.modal
	{
		fieldset
		{
			margin-bottom: size(75);
		}
	}

}