@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	background-color: color('white2');
	padding-top: size(75)!important;
	padding-bottom: size(80);

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	.videoitem{
		width: size(200);
		margin: 0 auto;
		margin-bottom: size(24);

		.image
		{
			width: 100%;
			margin-right: size(20);
			flex-shrink: 0;
			margin-bottom: size(16);
			height: size(200);
			img
			{
				border-radius: size(16);
				width: size(200);
				height: size(200);
			}
			.play-icon
			{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.caption
		{
			font-size: size(12);
			font-weight: bold;
			text-align: center;
		}

	}

	ul.items
	{
		display: flex;
		justify-content: space-around;
		margin-bottom: size(-24);

		li
		{
			width: size(88);
			margin-bottom: size(24);
			white-space: break-spaces;

			.image
			{
				width: 100%;
				margin-right: size(20);
				flex-shrink: 0;
				margin-bottom: size(16);
				height: size(88);
				img
				{
					border-radius: size(16);
					width: size(88);
					height: size(88);
				}
				.play-icon
				{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(60%);
				}
			}

			.caption
			{
				font-size: size(12);
				font-weight: bold;
				text-align: center;
			}

		}
	}
	.highlight
	{
		font-weight: bold;
		font-size: size(17);
		color: color('subtext');
		text-align: center;
		margin-top: size(24, 17);
		margin-bottom: size(3, 17);

	}

}