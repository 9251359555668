@import '/src/css/General.scss';

.loading-wrapper
{
	.standalone &{
		.loader
		{
			background-color: rgba(0, 0, 0, 0.8);
		}
	}
	&.standalone {
		.loader
		{
			background-color: rgba(0, 0, 0, 0.8);
		}
		.loading-content
		{
			.loading-message{
				color: color('white')!important;
			}
		}

	}
	.loader
	{
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		// max-width: min(768px, 100vw);
		z-index: 500000;
		background-color: rgba(255, 255, 255, 0.8);
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s ease-out;
		
		.standalone &{
			background-color: rgba(0, 0, 0, 0.8);
		}
		&.show
		{
			opacity: 1;
			pointer-events: all;
			.spinner
			{
				span
				{
					animation-name: spinner-fade;
				}
			}
		}


		.loading-content
		{
			display: flex;
			height: 100%;
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;


			// position: absolute;
			// top: 50%;
			// left: 50%;
			.spinner
			{
				margin-top: size(60);
				height: size(40);
				span
				{
					display: block;
					width: size(16);
					height: size(7);
					background-color: color('primary');
					position: absolute;
					transform-origin: 0 50%;
					border-radius: size(7);
					animation-duration: 2s;
					animation-iteration-count: infinite;
				}
	
				$num: 9;
				$angle: 360 / $num;
				@for $i from 1 through $num {
					span:nth-child(#{$i})
					{
						transform: rotate(#{($i - 1) * $angle}deg) translateX(size(13)) ;
						animation-delay: #{($i / $num) * 2}s;
					}
				}
			}
			.loading-message
			{


				// position: absolute;
				// width: 90vw;
				text-align: center;

				// top: size(40);
				// transform: translateX(-50%);
				color: color('text');
				white-space: pre-wrap;
				.progress
				{
					@extend .bold;
					font-size: size(23);
					font-family: 'lato';
					color: color('primary');
					// margin-top: size(-40);
				}

				.progressbar
				{
					background-color: color('white');
					width: size(208);
					height: size(7);
					display: block;
					border-radius: size(7);
					margin: 0 auto;
					margin-top: size(18);
					margin-bottom: size(30);
				.progressbar-bar
					{
						background-color: color('primary');
						height: size(7);
						display: block;
						border-radius: size(7);
						transition: width 0.1s;
					}
				}

				.caption
				{
					margin: 0.4em 0;
				}
				.standalone &{
					color: color('white');
				}
			}
		}

		.loading-video
		{
			margin-top: rem(30);
			width: rem(307);
			height: rem(214);
		}

	}
}

@keyframes spinner-fade {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	25% {
		opacity: 0.1;
	}
  
	// 30% {
	// 	opacity: 0.75;
	// }
  
	// 75% {
	// 	opacity: 0.5;
	// }
  
	// 80% {
	// 	opacity: 0;
	// }

	100% {
		opacity: 1;
	}
  }