@import '/src/css/General.scss';

.back-link
{
	@extend .caption;
	@extend .bold;

	font-size: size(12);
	color: color('subtext')!important;
	margin-bottom: size(31, 12);
	.icon
	{
		margin-right: 0.2em;
	}

	&.padded
	{
		margin-left: size(24, 12);
	}
}
