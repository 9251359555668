@import '/src/css/General.scss';

.movie-section-thumb
{
	margin: size(4);
	flex: 1;
	background-color: color('white2');
	border-radius: size(16);
	padding: size(16);
	>.label
	{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-bottom: size(12);
		.index
		{
			@extend .bold;
			font-family: 'lato';
			font-size: size(16);
			display: inline-block;
			width: size(27, 16);
			height: size(27, 16);
			line-height: size(26, 16);
			background-color: color('secondary');
			color: color('white');
			border-radius: 2em;
			text-align: center;
			margin-bottom: size(4);
		}
		.label-text
		{
			@extend .bold;
			font-size: size(12);
			color: color('secondary');
		}
	}
	.preview
	{
		display: block;
		width: 100%;
		height: auto;
		aspect-ratio: 1;
		width: size(116);
		height: size(116);
		border-radius: size(4);
		border: 1px solid color('white');
		overflow: hidden;
		background-color: color('placeholder');
		margin: 0 auto;
		.play-icon
		{
			position: absolute;
			width: 26%;
			height: auto;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	.round-button
	{
		min-height: size(36);
		margin-bottom: 0;
		background-color: color('white');
		.label
		{
			font-size: size(12);
		}
	}
}
