@import 'src/css/General.scss';
@import '/src/core/screens/Page.scss';

.container
{
	padding: size(40) size(24) size(40)!important;
	background-color: color('white2');
  margin-bottom: 0!important;

	&.fromLP
	{
		padding-top: size(30)!important;
		padding-bottom: size(40)!important;
		.description
		{
			margin-bottom: size(24, 24);
		}
	}

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	.thumb
	{
		img
		{
			width: 100%;
			border-radius: size(17);
		}

		.icon
		{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}