@import 'src/css/General.scss';

fieldset.code-input-field
{
	position: relative;
	padding-bottom: size(24);
	.container
	{
		display: flex;
		justify-content: space-between;
		.input-field
		{
			display: inline-flex;
		}
	}

	label
	{
		@extend .caption;
		@extend .bold;
		display: block;
		margin: size(10, 12) 0;

		.description
		{
			padding-left: size(10, 12);
			color: color('subtext');
		}
	}

	input, textarea
	{
		background-color: color('white2');
		border: 1px solid color('border2');
		transition: 0.1s border, 0.1s background-color, 0.1s padding;

		font-size: size(28);
		width: size(74, 28);
		line-height: size(74, 28);
		padding: 0!important;
		text-align: center;
		border-radius: size(16, 28);

	}

	textarea
	{
		min-height: size(130, 15);
	}

	.error-message
	{
		@extend .caption;
		@extend .bold;
		color: color('error');
		position: absolute;
		// top: calc(100% + #{size(6, 12)});
		bottom: 0;
	}

	&.error
	{
		input, textarea
		{
			border: 2px solid color('error');
			background-color: color('errorLight');
			padding: calc(#{size(20)} - 1px);
		}
	}

	.toggle-password
	{
		position: absolute;
		// top: 0px;
		right: size(12);
		// bottom: 0px;
		z-index: 2;
		transform: translateY(size(14));
		i{
			font-size: size(24);
		}
	}
}