@import '/src/css/General.scss';

.toast
{
	padding: rem(10) rem(16) rem(14);
	&.theme-default
	{
		--toast-bg: #{color('white2')};
		--toast-text: #{color('black')};
		--toast-link: #{color('primary')};
	}

	&.theme-red
	{
		--toast-bg: #{color('alt')};
		--toast-text: #{color('white')};
		--toast-link: #{color('white')};
	}

	&.theme-blue
	{
		--toast-bg: #{color('secondary')};
		--toast-text: #{color('white')};
		--toast-link: #{color('white')};
	}
	background-color: var(--toast-bg);

	.container
	{
		width: 100%;
	}

	.content
	{
		z-index: 100;
		@extend .bold;
		@extend .caption;
		display: flex;
		justify-content: space-between;
		width: 100%;
		font-size: size(12);
    font-weight: 700!important;
		color: var(--toast-text);
    line-height: 1;
    a
		{
			color: var(--toast-link);
      line-height: 1;
		}
		[class="action-label"]
		{
      line-height: 1;
			color: var(--toast-link);
			display: flex;
			flex-shrink: 0;
			align-items: center;
			[class*="icon"]
			{
				margin-bottom: -0.1em;
			}
		}
	}
}