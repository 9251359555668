@import '/src/css/General.scss';

section.HomeNewsPage
{
	padding: 0!important;
}

.HomeNewsPage
{
	background-color: color('white2');
	padding-top: size(75)!important;
	padding-bottom: size(75)!important;
	.title
	{
		@extend .h2;
		text-align: center;
		margin-top: 0;
		.icon
		{
			font-size: size(22, 20);
			margin-bottom: size(5, 20);
			color: color('primary');
		}
	}

	.items
	{
		.item
		{
			display: block;
			margin-bottom: size(36);
			.date
			{
				@extend .bold;
				font-size: size(13);
				color: color('gray2');
				margin-bottom: size(16, 13);
			}
			.text
			{
				@extend .bold;
				font-size: size(18);
				color: color('black');
				i
				{
					font-weight: normal;
					margin-left: size(2);
					bottom: size(-1);
				}
			}
		}
	}

	.buttons
	{
		margin-top: size(70);
	}
}