@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

// hide default Header
.GeneralLPPage-app,
.WeddingLPPage-app,
.BirthdayLPPage-app,
.FarewellLPPage-app,
.GraduationLPPage-app,
.EasythanvideoappLPPage-app{
	.App{
		margin-top: 0 !important;
	}
	header.header{
		display: none !important;
	}
}


// LPPage style

.GeneralLPPage,
.WeddingLPPage,
.BirthdayLPPage,
.FarewellLPPage,
.GraduationLPPage,
.EasythanvideoappLPPage{
	padding: 0 !important;


	.bg-white{
		background-color: color('white') !important;
	}
	.bg-white2{
		background-color: color('white2') !important;
	}

	.createVideoButtons{
		display: flex;
		flex-direction: column;
		align-items: center;

		span{
			font-size: 12px;
			color: color('subtext');
			font-weight: bold;
			text-align: center;
		}
	}

	.lpHeader{
		display: flex;
		justify-content: center;
		margin: size(30) auto;

		&.topcaption{
			margin-bottom: 0
		}
	}

	.lpFirstview{
		@include padding;
		margin-bottom: size(40);
		.video
		{
			width: size(327);
			height: size(327);
			border-radius: size(40);
			overflow: hidden;
			margin-bottom: size(20);
			video
			{
				border-radius: size(40);
			}
		}
		.title
		{
			font-size: size(30);
			font-weight: 900;
			line-height: 1.4em;
			margin: size(20, 32) 0;
			text-align: center;
			white-space: pre;
			letter-spacing: -0.03em;
		}

		.slashballoon{
			display: flex;
			justify-content: space-between;
			font-size: 20px;
			font-weight: 500;
			margin: 21px 0 11px;
			white-space: pre-wrap;
			text-align: center;

			&.generalLP{
				justify-content: space-evenly;
				font-weight: 700;
				font-size: 15px;
				line-height: 22px;
			}
		}

		&.topcaption{
			.title{
				letter-spacing: 0;
				margin-top: 16px;
			}
		}
	}

	.lpNeeds{
		margin-top: size(80);

		.balloon{
			width: 100%;
			max-width: 315px;
			margin: 0 auto;
			background-color: #FEE5DF;
			border-radius: 16px;
			padding: 29px 0;
			text-align: center;
			position: relative;

			.title{
				font-weight: 700;
				white-space: pre-wrap;
				font-size: size(20);
			}
			.caption{
				color: color('subtext');
				font-weight: 700;
				margin-top: 6px;
			}

			img{
				position: absolute;
				right: calc(50% - 15px);
				bottom: -15px;
				width: 30px;
				height: 30px;
			}
		}

		.picture{
			img{
				width: 100%;
				margin-top: size(26);
			}
		}
	}
	
	.lpWorries{
		padding-top: 40px;
		background-color: color('white2');

		.balloon{
			width: 100%;
			max-width: 315px;
			margin: 0 auto;
			background-color: #FEE5DF;
			border-radius: 16px;
			padding: 16px 0;
			text-align: center;
			position: relative;

			.title{
				font-weight: 700;
				white-space: pre-wrap;
				font-size: size(20);
			}
			.caption{
				color: color('subtext');
				font-weight: 700;
				margin-top: 6px;
			}

			img{
				position: absolute;
				right: calc(50% - 15px);
				width: 30px;
				height: 30px;
			}
		}

		.checklist{
			margin: 36px auto;
			margin-top: 0;
			max-width: 234px;
			.item{
				display: flex;
				font-size: 17px;
				font-weight: 700;
				white-space: pre-wrap;
				margin-bottom: 20px;

				img{
					margin-right: 13px
				}
			}
		}

		.arrow{
			display: flex;
			justify-content: center;
			margin-bottom: size(31);
		}

		.caption{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			font-weight: 700;
			letter-spacing: .08em;
			padding-bottom: 40px;

			.sub{
				font-size: 17px;
				margin-bottom: size(2);
				white-space: pre-wrap;
				color: color('primary');
			}
			.main{
				font-size: 24px;
				padding: 0 5px;
			}
		}
	}

	.lpPoints{
		@include padding;
		margin-top: size(50);

		.title{
			font-size: size(24);
			font-weight: 600;
			color: color('text');
			margin-bottom: size(24);

			.primary{
				font-size: 16px;
				font-weight: 700;
				color: color('primary');
				letter-spacing: .08em;
			}
		}

		.point{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: size(32);
			white-space: pre-wrap;

			.text{
				.primary{
					color: color('alt');
					font-size: 12px;
					font-weight: 900;
					margin-bottom: size(9);
					font-family: 'lato';
					letter-spacing: .08em;
				}
				.title{
					font-size: size(17);
					font-weight: 700;
					margin-bottom: size(5);
				}
				.message{
					font-size: 12px;
					font-weight: 700;
					color: color('subtext');
					max-width: 177px;
				}
			}
			.img{
				display: flex;
				align-items: center;
			}
		}

		
	}

	.lpEffort{
		margin-top: size(36);
		.arrow{
			display: flex;
			justify-content: center;
			margin-bottom: size(31);
		}
		.caption{
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			font-weight: 700;
			letter-spacing: .08em;

			.sub{
				font-size: 17px;
				margin-bottom: size(2);
			}
			.main{
				font-size: 24px;
				background: linear-gradient(0deg, rgba(247, 81, 41, 0.4), rgba(247, 81, 41, 0.4) 30%, transparent 30%, transparent 100%);
				width: fit-content;
				padding: 0 5px;
			}

			&.mt30{
				margin-top: 30px;
			}
		}

		.bgcolor{
			background-color: color('white2');
			margin-top: size(50);
			padding-bottom: size(50);

			.balloons{
				padding-top: size(50);

				img{
					display: block;

					&:first-of-type{
						margin-bottom: size(10);
						margin-left: size(57);
					}
					&:nth-of-type(2){
						margin-left: size(17);
					}
				}
			}

			.guides{
				display: flex;
				justify-content: space-evenly;
				margin-top: size(40);
				margin-bottom: size(18);

				img{
					max-width: 128px;
				}
			}

			.createbuttonwrap{
				@include padding;
				margin-top: size(38);
			}
		}

		.balloon{
			margin-top: 30px;
			margin-left: 30px;
		}
	}
	
	.SampleMovie{
		background: none;
		padding-top: 33px !important;

		.createVideoButtons{
			margin-top: size(40);
		}

		.description{
			text-align: center;
			
			span.underline{
				background: linear-gradient(0deg, rgba(247, 81, 41, 0.4), rgba(247, 81, 41, 0.4) 25%, transparent 25%, transparent 100%);
			}
		}

	}

	.Howto{
		background-color: color('white2');
		.createVideoButtons{
			margin-top: size(40);
		}
	}

	.pricing{
		background: none;

		.items li{
			background-color: color('white2');
		}

		.plus{
			margin-bottom: 32px;
		}
	}

	.reviews{
		background-color: color('white2');

		.items li{
			background: color('white');
		}
	}

	.faq{
		.buttons{

			.seemore{
				max-width: 265px;
				margin: auto;
			}
			.createVideoButtons{
				width: 100%;
				margin-top: size(40);
			}
		}
	}

	.cta{
		.buttons{
			@include padding;
		}
	}
}

.EasythanvideoappLPPage{
	.SampleMovie{
		padding-top: 33px !important;

		.description{
			text-align: center;

			span.underline{
				background: linear-gradient(0deg, rgba(247, 81, 41, 0.4), rgba(247, 81, 41, 0.4) 25%, transparent 25%, transparent 100%);
			}
		}
	}
}