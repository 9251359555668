@import '/src/css/General.scss';

.standalone-player
{
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	pointer-events: all;
	.video
	{
		width: min(768px, 100vw);
		height: min(768px, 100vw);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	.closeBtn
	{
		position: absolute;
		// top: calc(50% + 55vw);
		// bottom: 10vh;
		bottom: 10vh;
		left: 50%;
		transform: translateX(-50%);
		width: size(57);
		height: size(57);
	}

	.video-overlay
	{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: auto;
		z-index: 200;
		transform: translate(-50%, -50%);
		pointer-events: none;

	}
}