@import '/src/css/General.scss';


.FAB
{
	position: fixed;
	bottom: size(24);
	right: calc(calc(calc(100vw - #{size(375)}) * 0.5) + #{size(24)});
	width: size(82);
	height: size(82);
	margin-bottom: 0;
	white-space: pre-wrap;
	z-index: 90;
	line-height: 1em;
	&::before, &::after
	{
		border-radius: size(100)!important;
	}
	.icon
	{
		font-size: size(24);
		margin-bottom: size(2, 24);
	}
	.label
	{
		font-size: size(10);
		margin-bottom: 0;
		line-height: 1.5em;
	}
}