@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	padding-top: size(75)!important;
	padding-bottom: size(80);
	background-color: color('white2');

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	ul.items
	{
		display: flex;
		width: size(265);
		margin-left: size(-8);

		li
		{
			flex-shrink: 0;
			display: inline-block;
			width: size(265);
			background-color: color('white');
			padding: size(24);
			border-radius: size(24);
			margin-right: size(12);

			.title
			{
				font-size: size(17);
				font-weight: bold;
				margin-bottom: size(19, 17);
			}
			.description
			{
				font-weight: normal;
				font-size: size(15);
				margin-bottom: size(19, 17);
			}
			.name
			{
				font-size: size(12);
				color: color('subtext');
			}
		}

	}
}