@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.HomeAboutPage
{
	text-align: center;
	margin-top: size(50)!important;
	margin-bottom: size(120)!important;
	h2
	{
		text-align: center;
		margin-bottom: size(22, 20);
		margin-top: size(12, 20);
	}
	.description
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		margin-bottom: size(12, 12);
	}
	.content
	{
		margin-top: size(15);
		margin-bottom: size(42);
	}
	.video
	{
		width: size(351);
		height: size(233);
		margin: 0 auto;
	}
	img
	{
		width: 100%;
		height: auto;
	}
}