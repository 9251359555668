@import '/src/css/General.scss';

.show-modal
{
	margin-top: 0;
	min-height: 100vh;
	// overflow: hidden;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	// background-color: color('text');
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 100000;
	overflow: auto;
	padding-bottom: calc(100vh - 100svh);

	.modal-padding
	{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: size(13);
		min-height: 100vh;
		width: 100%;
	}

	.modal-container
	{
		padding: size(40) size(20) size(36);
		background-color: color('white');
		border-radius: size(16);
		width: 100%;
		white-space: pre-wrap;
		max-width: size(480);
		overflow: hidden;

		@include breakpoint('medium')
		{
			padding: size(40);
		}
		@include breakpoint('large')
		{
			padding: size(40);
		}
		.close-btn
		{
			position: absolute;
			top: size(17);
			right: size(17);
			.icon
			{
				font-size: size(20);
			}
		}

		.title, .text, .description
		{
			white-space: pre-wrap;
			a
			{
				text-decoration: underline;
			}
		}

		.image
		{
			text-align: center;
			margin-bottom: size(24);
		}

		.title
		{
			@extend .h3;
			text-align: center;
			margin-bottom: size(16, 17);
		}
		.text
		{
			@extend .caption;
			@extend .bold;
			text-align: center;
			margin-bottom: size(16, 17);
		}
		.description
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			text-align: center;
			white-space: pre-wrap;
			margin-bottom: size(20, 12);
      line-height: 1.2em;
      font-size: rem(12);
		}
		.buttons
		{
			margin-top: size(40);
			.link
			{
				color: color('primary');
				font-size: size(12);
				@extend .bold;
			}
		}

	}
}
