@import '/src/css/General.scss';

.title
{
	@extend .h1;
	font-size: size(24);
	a
	{
		color: color('primary');
	}
}

.items
{
	margin-top: size(40);
	.item
	{
		display: block;
		margin-bottom: size(36);

		.thumb
		{
			display: block;
			width: 100%;
			height: size(184);
			background-size: cover;
			background-position: center;
			margin-bottom: size(16);
			border-radius: size(16);
			overflow: hidden;
		}
		.date
		{
			@extend .bold;
			font-size: size(13);
			color: color('gray2');
			margin-bottom: size(16, 13);
		}
		.text
		{
			@extend .bold;
			font-size: size(18);
			color: color('black');
			// display: flex;
			// align-items: baseline;
			i
			{
				font-weight: normal;
				margin-left: size(2);
				bottom: size(-1);
			}
		}

		.tags
		{
			@extend .bold;
			font-size: size(13);
			margin-top: size(10, 13);
			color: color('gray2');
			.tag
			{
				display: inline;
				margin-right: size(8);
			}
		}
	}
}

.buttons
{
	margin-top: size(70);
}
