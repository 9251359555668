@import 'src/css/General.scss';

.modal-purchasePlus{
	.modal-container{
		position: relative;

		.confetti{
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
		}

		.title{
			font-weight: 700;
			font-size: 20px !important;
			margin-bottom: 0;
      margin-top: size(8);
		}
	
		.items{
			margin: rem(16) auto;
			max-width: size(282);
      img:first-child
      {
        margin-top: 0;
      }
		}
	

		.prices{
			.caption{
				font-size: 12px;
				color: color('subtext');
				font-weight: 700;
				margin: 0 auto;
				line-height: 1;
			}

			.pricebox{
				display: flex;
				justify-content: center;
				align-items: flex-end;
				margin-top: size(7, 15);

				.plus-icon
				{
					background-color: color('alt');
					font-size: size(11, 15);
					width: size(30, 11);
					height: size(30, 11);
					border-radius: size(30, 11);
					display: flex;
					justify-content: center;
					align-items: center;
					color: color('white2');
					margin-right: size(4, 11);
					margin-bottom: size(2, 11);
				}

				.price {
					font-size: size(36, 15);
					font-family: 'lato';
					font-weight: 700;
					line-height: 1;
					margin-right: 4px;
				}
				.tax{
					font-weight: 700;
					font-size: 11px;
					line-height: 16px;
				}

			}
			.note
			{
				@extend .caption;
				@extend .bold;
				margin-top: size(17, 12);
				color: color('error');
			}
		}

		.buttons{
			margin-top: 24px;
		}

	}

}