@use 'sass:color';
@use 'sass:list';
@import 'src/css/General.scss';

$button-colors: (
	'primary': (color('primary'), color('primary2'), color('white')),
	'disabled': (color('inactive'), color('border'), color('white')),
	'white': (color('white'), color('white'), color('primary')),
	'grey': (color('subtext'), color('subtext'), color('subtext')),
	'line': (#06C755, #359A51, color('white')),
	'red': (#E43737, #C62C2C, color('white')),
);


.round-button, .round-button:visited
{
	@extend .bold;
	font-size: size(15);
	margin: size(12, 15) 0;
	min-height: 4.26em;
	border-radius: 4.26em;
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	.button-icon
	{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 1.5em;
		z-index: 5;
	}

	&.slim
	{
		min-height: 3.2em;
	}

	&.disabled
	{
		pointer-events: none;
	}


	@each $k, $v in $button-colors {
		&.#{$k}
		{
			border: size(2) solid list.nth($v, 1);
			color: list.nth($v, 1);
		}
	}
	@extend .primary;
	.label
	{
		z-index: 3;
	}

	&.filled
	{
		border: 0;
		&::before
		{
			content: '';
			border-radius: 4.26em;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			bottom: size(3);
			z-index: 2;
		}

		&::after
		{
			content: '';
			border-radius: 4.26em;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			height: 100%;
			z-index: 1;
		}		
		@each $k, $v in $button-colors {
			&.#{$k}
			{
				color: list.nth($v, 3);
				&::before
				{
					background-color: list.nth($v, 1);
				}
				&::after
				{
					background-color: list.nth($v, 2);
				}
			}
		}
		@extend .primary;
	}

	&.bg-white{
		background-color: color('white');
	}

}

