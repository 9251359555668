@import 'src/css/General.scss';

.ParticipateMoviePage
{
	.expiration
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		margin-top: size(12, 12);
		margin-bottom: size(40, 12);
	}
	form
	{
		margin-top: size(24);
	}
	.note
	{
		@extend .caption;
		color: color('subtext');
		a
		{
			text-decoration: underline;
		}
	}
	.buttons
	{
		margin-top: size(24);
	}
	.text
	{
		text-align: center;
		margin: size(15) 0;
	}

	.title
	{
		text-align: center;
		margin-top: size(16);
	}

	.description
	{
		@extend .h3;
		text-align: center;
		margin-bottom: size(16);
	}

	.video
	{
		margin: 0 auto size(40);
		width: size(280);
		height: size(184);
	}


	.participate
	{
		padding-bottom: size(12);
		.content
		{
			border-radius: size(16);
			border: 1px dashed color('inactive');
			padding: size(28) size(22);
			margin: size(12) 0;

			a
			{
				@extend .caption;
				@extend .bold;
				display: block;
				color: color('primary');
				margin: size(8, 12) 0;
				word-break: break-all;
			}
		}
	}
}