@import 'src/css/General.scss';

.countdown
{
	canvas
	{
		position: absolute;
	}
	.count
	{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: color('white');
		font-family: 'lato';
	}
}