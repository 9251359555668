@font-face {font-family: "icons";
  src: url('icons.eot?t=1696168360248'); /* IE9*/
  src: url('icons.eot?t=1696168360248#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("icons.woff2?t=1696168360248") format("woff2"),
  url("icons.woff?t=1696168360248") format("woff"),
  url('icons.ttf?t=1696168360248') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('icons.svg?t=1696168360248#icons') format('svg'); /* iOS 4.1- */
}

[class^="icons-"], [class*=" icons-"] {
  font-family: 'icons' !important;
  font-size: 16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icons-account:before { content: "\ea01"; }
.icons-add-circle:before { content: "\ea02"; }
.icons-alert-triangle:before { content: "\ea03"; }
.icons-back-arrow:before { content: "\ea04"; }
.icons-back:before { content: "\ea05"; }
.icons-calendar:before { content: "\ea06"; }
.icons-camera-roll:before { content: "\ea07"; }
.icons-camera:before { content: "\ea08"; }
.icons-check-circle:before { content: "\ea09"; }
.icons-check-overflow:before { content: "\ea0a"; }
.icons-check:before { content: "\ea0b"; }
.icons-clipboard:before { content: "\ea0c"; }
.icons-close-circle:before { content: "\ea0d"; }
.icons-close-outline:before { content: "\ea0e"; }
.icons-close-round:before { content: "\ea0f"; }
.icons-close:before { content: "\ea10"; }
.icons-contact:before { content: "\ea11"; }
.icons-copy:before { content: "\ea12"; }
.icons-download-ios:before { content: "\ea13"; }
.icons-download:before { content: "\ea14"; }
.icons-edit:before { content: "\ea15"; }
.icons-external:before { content: "\ea16"; }
.icons-faq:before { content: "\ea17"; }
.icons-guide-nonfill:before { content: "\ea18"; }
.icons-guide:before { content: "\ea19"; }
.icons-images:before { content: "\ea1a"; }
.icons-instagram:before { content: "\ea1b"; }
.icons-invite:before { content: "\ea1c"; }
.icons-line:before { content: "\ea1d"; }
.icons-link-arrow:before { content: "\ea1e"; }
.icons-login:before { content: "\ea1f"; }
.icons-logout:before { content: "\ea20"; }
.icons-menu:before { content: "\ea21"; }
.icons-movie_list:before { content: "\ea22"; }
.icons-music:before { content: "\ea23"; }
.icons-news:before { content: "\ea24"; }
.icons-note:before { content: "\ea25"; }
.icons-password-hide:before { content: "\ea26"; }
.icons-password-show:before { content: "\ea27"; }
.icons-pause:before { content: "\ea28"; }
.icons-play:before { content: "\ea29"; }
.icons-plus-thin:before { content: "\ea2a"; }
.icons-plus:before { content: "\ea2b"; }
.icons-question:before { content: "\ea2c"; }
.icons-rec:before { content: "\ea2d"; }
.icons-receipts:before { content: "\ea2e"; }
.icons-record:before { content: "\ea2f"; }
.icons-reload:before { content: "\ea30"; }
.icons-reorder:before { content: "\ea31"; }
.icons-ribbon:before { content: "\ea32"; }
.icons-share-android:before { content: "\ea33"; }
.icons-share-ios:before { content: "\ea34"; }
.icons-share:before { content: "\ea35"; }
.icons-shopping-cart:before { content: "\ea36"; }
.icons-switch-camera:before { content: "\ea37"; }
.icons-twitter:before { content: "\ea38"; }
.icons-youtube:before { content: "\ea39"; }

$icons-account: "\ea01";
$icons-add-circle: "\ea02";
$icons-alert-triangle: "\ea03";
$icons-back-arrow: "\ea04";
$icons-back: "\ea05";
$icons-calendar: "\ea06";
$icons-camera-roll: "\ea07";
$icons-camera: "\ea08";
$icons-check-circle: "\ea09";
$icons-check-overflow: "\ea0a";
$icons-check: "\ea0b";
$icons-clipboard: "\ea0c";
$icons-close-circle: "\ea0d";
$icons-close-outline: "\ea0e";
$icons-close-round: "\ea0f";
$icons-close: "\ea10";
$icons-contact: "\ea11";
$icons-copy: "\ea12";
$icons-download-ios: "\ea13";
$icons-download: "\ea14";
$icons-edit: "\ea15";
$icons-external: "\ea16";
$icons-faq: "\ea17";
$icons-guide-nonfill: "\ea18";
$icons-guide: "\ea19";
$icons-images: "\ea1a";
$icons-instagram: "\ea1b";
$icons-invite: "\ea1c";
$icons-line: "\ea1d";
$icons-link-arrow: "\ea1e";
$icons-login: "\ea1f";
$icons-logout: "\ea20";
$icons-menu: "\ea21";
$icons-movie_list: "\ea22";
$icons-music: "\ea23";
$icons-news: "\ea24";
$icons-note: "\ea25";
$icons-password-hide: "\ea26";
$icons-password-show: "\ea27";
$icons-pause: "\ea28";
$icons-play: "\ea29";
$icons-plus-thin: "\ea2a";
$icons-plus: "\ea2b";
$icons-question: "\ea2c";
$icons-rec: "\ea2d";
$icons-receipts: "\ea2e";
$icons-record: "\ea2f";
$icons-reload: "\ea30";
$icons-reorder: "\ea31";
$icons-ribbon: "\ea32";
$icons-share-android: "\ea33";
$icons-share-ios: "\ea34";
$icons-share: "\ea35";
$icons-shopping-cart: "\ea36";
$icons-switch-camera: "\ea37";
$icons-twitter: "\ea38";
$icons-youtube: "\ea39";

