@import 'src/css/General.scss';

.icon
{
	border-radius: size(100);

	.inner
	{
		background-color: color('primary');
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.record
	{
		width: size(58);
		height: size(58);
		border: 3px solid color('white');
		.inner
		{
			width: size(44);
			height: size(44);
			border-radius: size(44);
		}
	}

	&.stop
	{
		border: 0;
		background-color: transparent;
		width: size(84);
		height: size(84);
		.progress
		{
			width: 100%;
			height: 100%;
		}
		.inner
		{
			width: size(29);
			height: size(29);
			border-radius: size(3);
		}
	}
}