@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.lpColumn{
	@include padding-large;
	padding-top: size(50) !important;
	padding-bottom: size(50);

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	.note-embed{
		border: 0; 
		display: block; 
		max-width: 100%; 
		width: 100%; 
		padding: 0px; 
		margin: 0px; 
		position: static; 
		visibility: visible;
	}
}