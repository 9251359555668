@import '/src/css/General.scss';

.check-box
{
	display: flex;
  align-items: flex-start;
  font-size: size(12);
  font-weight: bold;

	input
	{
		visibility: hidden;
		position: absolute;
	}
	.check-bullet
	{
		display: inline-flex;
		width: 1.66em;
		height: 1.66em;
		line-height: 0;
		border-radius: 0.2em;
		border: 1px solid color('inactive');
		background-color: color('white2');
		overflow: hidden;
		align-items: center;
		justify-content: center;
		margin-right: 0.66em;
		flex-shrink: 0;
		.check
		{
			// position: absolute;
			// top: 0;
			// left: 0;
			top: 0.05em;
			width: 0.8em;
			height: auto;
			visibility: visible;

      &>path{
        stroke: color('inactive');;
      }
		}

	}
	&.checked
	{
		.check-bullet
		{
			background-color: color('primary');
			border-color: color('inactive');
			.check
			{
				visibility: visible;

        &>path{
          stroke: color('white');
        }
			}
		}
	}
}