@import '/src/css/General.scss';

ul.items
{
	margin-left: rem(-8);
	margin-right: rem(-8);

}

.pricing-item
{
	background-color: color('white2');
	padding: rem(24) rem(20);
	border-radius: rem(13);
	margin-bottom: rem(16);
	overflow: hidden;
	.head
	{
		display: flex;
		margin-bottom: rem(16);
		align-items: flex-end;
		justify-content: space-between;

		.info
		{
			text-align: left;
			.title
			{
				font-size: rem(16);
				font-weight: bold;
				margin-bottom: 0;
			}
			.caption
			{
				font-size: rem(10);
				color: color('subtext');
				font-weight: bold;
				margin-right: rem(-100);
			}
		}
		.hidden
		{
			visibility: hidden;
		}
		
		div.price
		{
			display: flex;
			align-items: flex-end;
			span.price
			{
				font-size: rem(28);
				font-weight: bold;
        font-family: 'lato';
				line-height: 1em;
			}
			span.tax
			{
				font-size: rem(7);
				font-weight: bold;
				color: color('subtext');
				margin-left: rem(4);
			}
		}
	}

	ul.notes
	{
		li.note
		{
			display: flex;
			align-items: center;
			padding: 0;
			margin-bottom: rem(5);

			div.icon
			{
				background-color: color('ok');
				color: color('white');
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: rem(13);
				width: rem(16);
				height: rem(16);
				border-radius: 2em;
				padding-left: 0.1em;
				margin-top: 0.1em;
				margin-right: rem(6);

				&.disabled
				{
					background-color: color('border');
					font-size: rem(14);
					i
					{
						margin-left: -0.1em;
					}
				}
			}
			.text
			{
				font-size: rem(12);
				font-weight: bold;
				color: color('subtext');
				margin: 0;
			}
			.info-button
			{
				color: color('subtext');
				font-size: rem(18);
				margin-left: rem(2);
				margin-top: -0.5em;
				margin-bottom: -0.5em;
			}
		}

	}
}

.plus-icon
{
	font-size: rem(16);
	color: color('subtext');
	margin-top: rem(16);
	margin-bottom: rem(16);
	display: block;
}

.plus-content
{
	background-color: #EF7947;
	margin-left: rem(-8);
	margin-right: rem(-8);
	.confetti
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
	.title, .text, .caption, .price, .tax, .info-button
	{
		color: color('white')!important;
	}

	.caption
	{
		text-align: left;
		font-size: rem(10)!important;
		font-weight: bold;
	}

	ul.notes
	{
		li.note
		{
			display: flex;
			align-items: center;
			padding: 0;
			margin-bottom: rem(5);

			div.icon
			{
				border: 1px solid color('white');
			}	
		}
	}
}


.buttons
{
	margin-top: rem(24)!important;
	&:first-child
	{
		margin-top: 0!important;
	}
}
