@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	padding-top: size(75)!important;
	padding-bottom: size(80);
	background-color: color('white2');

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	ul.items
	{
		li
		{
			display: flex;
			align-items: center;
			margin-bottom: size(40);
			&:last-child
			{
				margin-bottom: 0;
			}
			.image
			{
				width: size(117);
				height: size(191);
				margin-right: size(19.9);
				flex-shrink: 0;
				img
				{
					position: absolute;
					width: auto;
					height: 100%;
				}
			}

			.info
			{
				.caption
				{
					font-size: size(16);
					font-weight: 900;
					color: color('alt');
					font-family: 'lato';
				}

				.title
				{
					font-size: size(17);
					font-weight: bold;
					margin: size(12, 17) 0;
					white-space: pre-wrap;
				}

				.description
				{
					font-size: size(12);
					font-weight: bold;
					color: color('subtext');
					margin-bottom: 1em;
					b
					{
						color: color('alt');
					}
				}

				.note
				{
					font-size: size(10);
					font-weight: bold;
					color: color('subtext');
				}
			}
		}
	}
	.highlight
	{
		font-weight: bold;
		font-size: size(17);
		color: color('subtext');
		text-align: center;
		margin-top: size(24, 17);
		margin-bottom: size(3, 17);

	}

}