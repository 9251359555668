@import 'src/css/General.scss';

.container
{
	padding: 0 size(38);
	padding-bottom: size(10);
	.bullets
	{
		display: flex;
		justify-content: stretch;
		align-items: center;

		--color: #{color('subtext')};

		.filled
		{
			--color: #{color('primary')};
		}

		.bullet
		{
			width: size(20);
			height: size(20);
			border-radius: size(20);
			background-color: var(--color);

			.icon
			{
				color: color('white');
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.label
			{
				color: var(--color);
				position: absolute;
				left: 50%;
				top: calc(100% + #{size(4, 12)});
				transform: translateX(-50%);
				font-weight: bold;
				font-size: size(12);
			}
		}
	
		.line
		{
			flex: 1;
			height: 1px;
			width: 10px;
			background-color: var(--color);
		}
	}
}