@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.page
{
	@include padding-large;
	background-color: color('white');
	padding-top: size(75)!important;
	padding-bottom: size(80);

	.description
	{
		white-space: pre-wrap;
		font-size: size(24, 'small');
		font-weight: bold;
		margin-top: size(12, 24);
		margin-bottom: size(40, 24);
	}

	ul.items
	{
		.item
		{
			border-radius: size(17);
			overflow: hidden;
			width: 100%;
			margin-bottom: size(24);

			img
			{
				width: 100%;
				height: auto;
				border-radius: size(17);
			}

			.bottom
			{
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				position: absolute;
				padding: size(20);
				width: 100%;
				background: linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				

				.caption
				{
					font-size: size(17);
					font-weight: bold;
					color: color('white');
				}

				.icon
				{
					display: inline-block;
					font-size: size(20);
					width: size(24, 20);
					height: size(24, 20);
					border-radius: 3em;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: color('primary');
					color: color('white');
					padding-left: 0.1em;
				}
			}
		}
	}
}