@import 'src/css/General.scss';

input, textarea
{
  font-family: 'notosansjp';
}

fieldset.input-field
{
	position: relative;
	margin-bottom: size(24);
	label
	{
		@extend .caption;
		@extend .bold;
		display: block;
		margin: size(10, 12) 0;
    text-align: left;
    font-size: rem(11);

		.description
		{
      font-size: rem(11);
			padding-left: 0.2em;
			color: color('subtext');
		}
	}

	.input, input, textarea
	{
		@extend .text;
    text-align: left;
		background-color: color('white2');
		border: 1px solid color('border2');
		padding: size(20);
		border-radius: size(16);
		width: 100%;
		transition: 0.1s border, 0.1s background-color, 0.1s padding;
    &:disabled
    {
      opacity: 0.5;
    }
	}

  .unset, ::placeholder
  {
    color: color('subtext');
  }

	.input
	{
		input
		{
			// visibility: hidden;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			// z-index: -1;
		}

		.icon
		{
			position: absolute;
			right: 0.7em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;

		}
	}

	textarea
	{
		min-height: size(130, 15);
	}

	.error-message
	{
		@extend .caption;
		@extend .bold;
		color: color('error');
		// position: absolute;
		margin-top: rem(2);
		bottom: 0;
	}

	.input-message
	{
		@extend .caption;
		@extend .bold;
		margin-top: size(11, 12);
		color: color('subtext');

		&.error
		{
			color: color('error');
		}
	}

	&.error
	{
		input, textarea
		{
			border: 2px solid color('error');
			background-color: color('errorLight')!important;
			padding: calc(#{size(20)} - 1px);
		}
	}

	.toggle-password
	{
		position: absolute;
		// top: 0px;
		right: size(12);
		// bottom: 0px;
		z-index: 2;
		transform: translateY(size(14));
		i{
			font-size: size(24);
		}
	}

	.reset-input
	{
		display: block;
		@extend .caption;
		@extend .bold;
		
		color: color('gray');
		text-align: right;
		margin-top: size(8, 12);
	}

}