@use "sass:math";

$breakpoints: (
    'small': 0px,
    'medium': 767px,
    'large': 1440px,
);

@mixin breakpoint($breakpoint) {
	$min: null;
	$max: null;
	$found: false;
	@each $k, $v in $breakpoints {
		@if $found
		{
			$max: $v;
			$found: false;
		}
		@if $k == $breakpoint {
			$min: $v;
			$found: true;
		}
	}
	@if $max{
		@media screen and (min-width: $min) and (max-width: $max) {
			@content;
		}
	}
	@else
	{
		@media screen and (min-width: $min) {
			@content;
		}
	}
}


.show-small
{
	display: none;
	@include breakpoint(('small'))
	{
		display: initial;
	}
}

.hide-small
{
	@include breakpoint(('small'))
	{
		display: none;
	}
}

.show-medium
{
	display: initial;
	@include breakpoint(('small'))
	{
		display: none;
	}
}


.hide-medium
{
	display: none;
	@include breakpoint(('small'))
	{
		display: initial;
	}
}