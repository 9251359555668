@import '/src/css/General.scss';

.pagination
{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	.arrow
	{
		margin-bottom: size(-1);
		color: color('black');
		padding: size(5) size(8);
		&.invert
		{
			transform: rotate(180deg);
		}
	}

	.separator
	{
		font-size: size(17);
		color: color('subtext');
		margin: 0 size(8);
	}

	a
	{
		font-size: size(17);
		color: color('subtext');
		line-height: 1;
		padding: size(5) size(8) size(6) size(9);
		margin: 0 size(2);
		&.selected
		{
			background-color: color('primary');
			color: color('white');
			border-radius: size(4);
		}
	}
}