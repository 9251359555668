@import '/src/css/General.scss';

.NewsDetailPage
{
	padding: 0!important;

	.date
	{
		font-size: size(13);
		color: color('gray2');
		margin-bottom: size(16, 13);
	}
	.title
	{
		@extend .h1;
		color: color('black');
		margin-bottom: size(30, 24);
	}

	.content
	{
		// @extend .bold;
		white-space: pre-wrap;
		.img
		{
			margin: size(32) 0;
			img
			{
				max-width: 100%;
			}
		}
		.note
		{
			@extend .caption;
			@extend .bold;
			margin: 1.5em 0;
			color: color('subtext');
		}

		.youtube
		{
			
		}
	}
}