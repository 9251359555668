@import '/src/css/General.scss';

.modal
{
	.title
	{
		font-size: rem(17);
		font-weight: bold;
		margin-bottom: rem(6)!important;
		b
		{
			color: color('primary');
		}
	}
	.description
	{
		font-size: rem(12);
		font-weight: bold;
		color: color('text')!important;
		b
		{
			color: color('primary');
		}
	}

	.panel
	{
		border-radius: rem(13);
		background-color: color('white2');
		text-align: left;
		padding: rem(16) rem(20) rem(24);
		.title
		{
			text-align: left;
			font-size: size(12)!important;
			font-weight: bold!important;
		}
		ul.notes
		{
			li.note
			{
				display: flex;
				align-items: center;
				padding: 0;
				margin-bottom: rem(5);
				&:last-of-type
				{
					margin-bottom: 0;
				}
	
				div.icon
				{
					background-color: color('ok');
					color: color('white');
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: rem(13);
					width: rem(16);
					height: rem(16);
					border-radius: 2em;
					padding-left: 0.1em;
					margin-top: 0.1em;
					margin-right: rem(6);
	
					&.disabled
					{
						background-color: color('border');
						font-size: rem(14);
						i
						{
							margin-left: -0.1em;
						}
					}
				}
				.text
				{
					font-size: rem(12);
					font-weight: bold;
					color: color('subtext');
					margin: 0;
				}
				.info-button
				{
					color: color('subtext');
					font-size: rem(18);
					margin-left: rem(2);
					margin-top: -0.5em;
					margin-bottom: -0.5em;
				}
			}
	
		}
			
	}

	.buttons
	{
		margin-top: rem(24)!important;
	}

	.link
	{
		margin-top: rem(22);
		display: block;
		font-size: rem(12);
		font-weight: bold;
		color: color('subtext');
		text-align: center;
	
	}

}