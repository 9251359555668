@import '/src/css/General.scss';

.footnote
{
	@extend .caption;
	@extend .bold;
	display: flex;
	color: color('subtext');
	.bullet
	{

	}
	.text
	{
		b
		{
			color: color('error');
		}
		a
		{
			text-decoration: underline;
			display: inline;
		}
	}
}