@import '/src/css/General.scss';

.download-modal
{
  [class='modal-container']
  {
    text-align: left;
  }
  .title
  {
    font-size: rem(17);
  }
	.instructions{
		.title, .text, .description
		{
			white-space: pre-wrap;
			a
			{
				text-decoration: underline;
			}
		}

		.title
		{
			@extend .h3;
			text-align: center;
      font-size: rem(17);
			margin-bottom: rem(16);
		}

		>.title
		{
			@extend .bold;
      text-align: center;
      font-size: rem(17);
		}
    >.description
    {
      text-align: left!important;
      color: color('black')!important;
    }
		.close-btn
		{
			position: absolute;
			top: rem(17);
			right: rem(17);
			.icon
			{
				font-size: rem(20);
			}
		}

		.text
		{
			@extend .caption;
			@extend .bold;
			text-align: center;
			margin-bottom: rem(16);
      font-size: rem(12);
		}
		.description
		{
			@extend .caption;
			@extend .bold;
			color: color('subtext');
			text-align: center;
			white-space: pre-wrap;
			margin-bottom: rem(20);
			font-size: rem(12);
		}
		.buttons
		{
			margin-top: rem(40);
		}


		.items
		{
			display: block!important;
			margin-top: rem(20);
			.block
			{
				border: 1px solid color('border2');
				border-radius: rem(16);
				padding: rem(20) rem(20);
				margin-bottom: rem(12);
				b
				{
					color: color('error');
				}
				.block-title
				{
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					height: auto;
					width: 100%;
					.value
					{
						@extend .bold;
						white-space: pre-wrap;
						flex: 1;
					}
	
					.icon
					{
						font-size: rem(24);
						transform: translateY(-#{rem(4)}) rotate(90deg);
						transition: transform 0.3s;
						margin-bottom: -1em;
            transform: rotate(90deg);
					}

					.description
					{
						width: 100%;
						margin: 0;
						margin-top: rem(4);
						padding: 0;
						text-align: left;
					}
				}

				.content .icon
				{
					font-size: rem(18);
					margin: 0 rem(4);
					bottom: -0.1em;
				}

				&.iphone
				{
					.content i
					{
						color: #5A90F4;
					}
				}
				&.android
				{
					.content i
					{
						color: color('primary');
					}
				}
				.content
				{
					height: 0;
					overflow: hidden;
					transition: height 0.3s;

					ol
					{
						@extend .bold;
						list-style: decimal;
						margin-left: 1.4em;
						padding-top: rem(30);
						li
						{
							list-style: decimal;
							margin-bottom: rem(30) 0;
	
							img
							{
								max-width: 100%;
                // margin: rem(20) auto 0;
							}
							
							.item-description
							{
								@extend .caption;
								color: color('subtext');
								margin: rem(16) 0;
                font-size: rem(12);
								.text, [class=text]
								{
									text-align: left;
									font-size: 1em;
									@extend .bold;
								}
							}
              .img
              {
                margin-bottom: rem(32)!important;
                &:last-child
                {
                  margin-bottom: 0!important;
                }
              }
	
						}
					}
					.content-title
					{
						@extend .bold;
						padding-top: rem(32);
						display: block;
						white-space: pre-wrap;
					}
					.round-button
					{
						margin-top: rem(23);
						margin-bottom: 0!important;
					}
				}
	
				&.open
				{
					.block-title
					{
						.icon
						{
							// transform: translateY(-#{rem(4)}) rotate(-90deg) scaleX(-1);
							transform: rotate(-90deg);
						}
					}

					.content
					{
						height: auto;
					}
				}
			}
		}
	}
	.notes
	{
		margin-top: rem(32);
		margin-bottom: rem(32);
		.note
		{
			@extend .caption;
			@extend .bold;
			font-size: rem(12);
			color: color('subtext');

			display: flex;
			margin-top: 0;
			margin-bottom: rem(8);
		}
	}
}
