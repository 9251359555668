@import 'src/css/General.scss';



menu.menu
{
	display: inline-block;
	z-index: 110;
	pointer-events: all;
	.btn
	{
		display: inline-block;
		width: size(24);
		height: size(24);
		z-index: 200;
		i
		{
			font-size: size(24);
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.3s transform ease-in-out;
			backface-visibility: hidden;
			&.hamburger
			{
				transform: rotateY(0deg) translateZ(10px);
			}
	
			&.close
			{
				transform: rotateY(180deg) translateZ(10px);
				position: fixed;
				top: size(20, 24);
				right: size(24, 24);
				left: unset;
			}
		}
	}
	&.open
	{
		.btn
		{
			.hamburger
			{
				transform: rotateY(-180deg) translateZ(10px);
			}
			.close
			{
				transform: rotateY(0deg) translateZ(10px);
			}
		}
		.container
		{
			background-color: rgba(0, 0, 0, 0.8);
			pointer-events: all;
			.content
			{
				left: size(60);
			}
		}
	}

	
	.container
	{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: 0.3s background-color ease-in-out;
		pointer-events: none;
		.content{
			position: fixed;
			top: 0;
			bottom: 0;
			left: 100%;
			padding: size(48) size(24) size(24) size(24);
			width: calc(100% - #{size(60)});
			transition: 0.3s left ease-in-out;
			z-index: 160;
			background-color: color('white');
			overflow-y: scroll;
			ul
			{
				li
				{
					a{
						width: 100%;
						@extend .bold;
						font-size: size(15);
						height: size(48, 15);
						vertical-align: middle;
						display: flex;
						align-items: center;
						.icon
						{
							color: color('primary');
							font-size: size(24);
							vertical-align: middle;
							margin-right: size(12, 24);
						}
			
					}
					&.separator
					{
						height: 0;
						font-size: size(24);
						border-bottom: 1px solid color(border);
						height: size(16, 24);
						margin-bottom: size(16, 24);
					}

					&.red{
						color: color('error');
						a .icon{
							color: color('error');
						}
					}
				}
			}

			.locales
			{
				@extend .caption;
				@extend .bold;
				margin: size(16) 0 size(46);
				display: flex;
				align-items: center;
				a
				{
					padding: 0 rem(12);
					&:first-child
					{
						padding-left: 0;
					}
					color: color('subtext');
					&.selected
					{
						color: color('text');
					}
				}

				.separator
				{
					height: 1em;
					border-right: 1px solid color('subtext');
				}
			}

			.socialAccout{
				margin: 34px 0;
				padding: rem(16);
				background-color: color('white2');
				border-radius: rem(16);
				display: flex;
				flex-direction: column;
				align-items: center;

				.title{
					font-size: rem(12);
					font-weight: 700;
					margin-bottom: rem(11);
				}
				.icons{
					display: flex;
					align-items: center;
				}
			}
		}
	}
}