@import 'src/css/General.scss';

footer.footer
{
	width: 100%;
	// margin-top: size(64);
	background-color: color('text');
	padding: size(62) size(24);
	color: color('white');

	.standalone &
	{
		display: none;
	}

	.links
	{
		@extend .text;
		@extend .bold;
		margin-top: size(40);
		ul
		{
			li
			{
				height: size(48, 15);
				align-items: center;
				display: flex;
			}
		}
	}
	.locales
	{
		@extend .caption;
		@extend .bold;
		margin: size(16, 12) 0 size(46, 12) 0;
		display: flex;
		align-items: center;
		a
		{
			padding: 0 size(12, 12);
			&:first-child
			{
				padding-left: 0;
			}
			color: color('subtext');
			&.selected
			{
				color: color('white');
			}
		}

		.separator
		{
			height: 1em;
			border-right: 1px solid color('subtext');
		}
	}

	.socialAccout{
		margin: 48px 0;
		.title{
			font-weight: 700;
			font-size: rem(12);
			margin-bottom: 16px;
		}
		.icons{
			display: flex;
			align-items: center;
		}
	}
	.legal
	{
		@extend .caption;
		@extend .bold;
		line-height: size(26, 12);
		margin: size(46) 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		a
		{
			display: inline-block;
			margin-right:size(12, 12);
			&:first-child
			{
				padding-left: 0;
			}
			color: color('footer');
		}

		.separator
		{
			display: inline-block;
			height: 1em;
			margin-right: size(12, 12);
			border-right: 1px solid color('footer');
		}
	}

	.copyright
	{
		@extend .caption;
		@extend .bold;
		color: color('footer');
	}

	.patent
	{
		background-color: transparentize(color('white2', true), 0.86);
		text-align: center;
		border-radius: size(16);
		padding: size(20);
		line-height: 1em;
		margin: size(48) 0;
		.title
		{
			font-size: size(17);
			font-weight: bold;
			margin-bottom: size(8, 17);
		}
		.description
		{
			font-size: size(12);
			font-weight: bold;
			color: color('subtext');
		}
	}

}