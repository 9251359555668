@import 'src/css/General.scss';

.modal
{
	line-height: 1;

	.title
	{
		font-size: rem(17)!important;
		line-height: 1;
	}

	.content
	{
		background-color: color('white2');
		border-radius: rem(12);
		padding: rem(24) rem(16) rem(22);
		margin-bottom: rem(24);
		.panels
		{
			width: 100%;
			display: flex;
			flex-direction: horizontal;
			gap: rem(9);
			justify-content: stretch;
			.panel
			{
				flex: 1;

				.check
				{
					visibility: hidden;
					position: absolute;
					background-color: color('ok');
					z-index: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0;
					left: 0;
					width: rem(32);
					height: rem(32);
					border-radius: rem(18);
					transform: translate(rem(-4.5), rem(-7));
					color: color('white');
					i
					{
						font-size: rem(20);
					}
				}

				&.done
				{
					.check
					{
						visibility: visible;
					}
					.panel-content
					{
						opacity: 0.5;
					}
				}

				&.current
				{
					.panel-content
					{
						border-color: color('secondary');
					}
				}

				.panel-content
				{
					background-color: color('white');
					border: 1px solid color('border');
					border-radius: rem(14.5);
					padding: rem(10) rem(14) rem(13);
					display: flex;
					flex-direction: column;
					align-items: center;
						
				}

				.index
				{
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'lato';
					font-size: rem(15.5)!important;
					color: color('white');
					width: rem(24.5);
					height: rem(24.5);
					border-radius: rem(20);
					background-color: color('secondary');
					margin-bottom: rem(4.3);
				}
				.label
				{
					margin-bottom: rem(3.9);
					font-size: size(12);
					font-weight: bold;
					color: color('secondary');
				}

				.caption
				{
					font-size: size(9);
					font-weight: bold;
					color: color('subtext');
				}

				.video
				{
					margin: rem(8) 0 rem(10.4);
					overflow: hidden;
					border-radius: rem(10.5);
					width: 100%;
					width: rem(95);
					height: rem(95);
				}

				.text
				{
					color: color('text');
					font-size: rem(11)!important;
					font-weight: bold;
					margin: 0 -2em;
					b
					{
						color: color('primary');
					}
				}
			}
		}

		.timeline
		{
			img
			{
				width: 100%;
				height: auto;
			}
		}
	}

	.highlight
	{
		color: color('primary')!important;
		font-size: rem(12)!important;
		font-weight: bold;
		margin-bottom: 0!important;

		&::before, &::after
		{
			content: '';
			border-left: 1px solid color(primary);
			height: 1.2em;
			display: inline-block;
			transform-origin: bottom;
		}
		&::before
		{
			transform: translateX(-0.6em) translateY(0) rotate(-45deg);
		}
		&::after
		{
			transform: translateX(0.6em) translateY(0) rotate(45deg);
		}
	
	}

	.buttons
	{
		margin-top: 0!important;
		margin-bottom: rem(8)!important;
		display: flex;
		flex-direction: horizontal;
		gap: rem(7);
	}

	.link
	{
		font-size: rem(12);
		font-weight: bold;
		color: color('subtext');
	}
}