@import 'src/css/General.scss';

.MovieCreatedPage
{
	.description
	{

	}
	
	.video
	{
		.video
		{
			margin: 0 auto size(40);
			width: size(280);
			height: size(184);
		}
		margin-top: size(18);
	}

	.caption
	{
		@extend .bold;
		color: color('primary');
		text-align: center;
		margin-top: size(12);
		.leftbar, .rightbar
		{
			border-left: 2px solid color(primary);
			height: size(22);
			display: inline-block;
			transform-origin: bottom;
		}
		.leftbar
		{
			transform: translateX(-#{size(10)}) translateY(size(2)) rotate(-30deg);
		}
		.rightbar
		{
			transform: translateX(#{size(10)}) translateY(size(2)) rotate(30deg);
		}
}
}