// GifviePlus 共通

@import 'src/css/General.scss';


.PlusEditPage-app,
.PlusPreviewPage-app{
	background-color: color('white2') !important;

	header.header,
	.App{
		background: none !important;
		padding-bottom: 0;
	}

	.page-movie{
		margin: 5px 0 0;
		padding: 0;
	}

}

.PlusEditPage,
.PlusPreviewPage{
	margin: 0 !important;

	.saved-toast{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		padding: size(20);
		color: color('white');
		display: flex;
		align-items: center;
		justify-content: center;
		@extend .bold;
		background-color: color('ok');
		transform: translateY(-100%);
		transition: transform 0.2s;
		opacity: .9;
		.icon
		{
			font-weight: normal;
			margin-right: 0.4em;
		}
		&.show
		{
			transform: translateY(0);
		}
		z-index: 200000;
	}

	.confetti_plustop{
		position: fixed;
		top: 0;
		width: 100%;
		max-width: 25em;
	}

	.body_header{
		padding: 0 1.6em;

		.pageback_button{
			color: color('subtext');
			font-size: 12px;
			font-weight: 700;
			display: flex;
			align-items: center;

			i{
				font-size: 16px;
			}
			background-color: color('white');
			padding: 7px 13px;
			border-radius: 32px;
		}
	}

	.body_card{
		background-color: color('white');
		box-shadow: 0px 14px 36px rgba(0, 0, 0, 0.15);
		margin-top: 17px;
		padding: 40px 24px;
		border-radius: 43px 43px 0 0;
	}

	.body_footer{
		position: fixed;
		bottom: 0;
		left: calc(50% - 165px);
		display: flex;
		justify-content: space-between;
		width: 330px;
		margin: 0 auto;
		z-index: 3;

		.round-button{
			width: 162px;
			filter: drop-shadow(0px 14px 36px rgba(0, 0, 0, 0.15));

			&.save.preview{
				width: 100%;
			}
		}

		.preview{
			background: white;
		}
	}
}