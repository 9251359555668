@import '/src/css/General.scss';

.NewsPage
{
	.title
	{
		@extend .h1;
	}

	.items
	{
		margin-top: size(40);
		.item
		{
			display: block;
			margin-bottom: size(36);
			.date
			{
				@extend .bold;
				font-size: size(13);
				color: color('gray2');
				margin-bottom: size(16, 13);
			}
			.text
			{
				@extend .bold;
				font-size: size(18);
				color: color('black');
				// display: flex;
				// align-items: baseline;
				i
				{
					font-weight: normal;
					margin-left: size(2);
					bottom: size(-1);
				}
			}
		}
	}

	.buttons
	{
		margin-top: size(70);
	}
}