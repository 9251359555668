@import '/src/css/General.scss';
#root.DownloadMoviePage-app {
	// background-color: color('white2')!important;
	.footer
	{
		margin-top: 0;
	}

	.page-movie
	{
		margin-bottom: 0;
	}
}

.DownloadMoviePage
{
	margin: 0 size(-24)!important;
	padding-bottom: 0;
	.top-content
	{
		background-color: color('white');
		.round-button
		{
			margin-top: size(50);
			margin-bottom: size(40);
		}
	}
	.description
	{
		@extend .text;
		margin-top: size(10, 15);
		margin-bottom: size(24, 15);
	}

	.expires
	{
		@extend .caption;
		@extend .bold;
		margin-top: size(-17, 12);
		color: color('subtext');
	}

	.content
	{
		background-color: color('white2');
		&.ready
		{
			background-color: color('white');
		}
		padding: size(40) 0;

		.items
		{
			margin-bottom: size(32);
			display: flex;
			justify-content: space-between;
			padding: 0 size(17);
			.item
			{
				width: calc(50% - 3px);
				text-align: center;

				.item-caption
				{
					@extend .caption;
					@extend .bold;
					color: color('primary');
					&::before, &::after
					{
						content: '';
						border-left: 2px solid color(primary);
						height: size(22);
						display: inline-block;
						transform-origin: bottom;
					}
					&::before
					{
						transform: translateX(-#{size(10)}) translateY(size(2)) rotate(-30deg);
					}
					&::after
					{
						transform: translateX(#{size(10)}) translateY(size(2)) rotate(30deg);
					}
		
				}
				.item-content
				{
					background-color: color('white');
					border-radius: size(24);
					padding: size(32) size(15) size(40);
					margin-top: size(9);
					.title
					{
						@extend .h3;
					}
					img
					{
						margin: size(18) auto;
						max-height: size(84);
					}
					div.price
					{
						margin-bottom: size(18);
						margin-left: size(-20);
						margin-right: size(-20);
						.price
						{
							font-size: size(27);
							font-family: 'lato', 'notosansjp';
							@extend .bold;
						}
						.price-note
						{
							@extend .bold;
							margin-left: 0.5em;
							font-size: size(9);
							color: color('subtext');
						}
					}

					ul
					{
						li
						{
							text-align: left;
							margin-bottom: size(8);
							display: flex;
							align-items: center;
							.check-icon
							{
								background-color: color('ok');
								color: color('white');
								display: inline-block;
								width: size(20);
								height: size(20);
								border-radius: size(20);
								display: inline-flex;
								align-items: center;
								justify-content: center;
								.icon
								{
									margin-left: 0.1em;
								}
								margin-right: size(6);
							}
							span
							{
								@extend .caption;
								@extend .bold;
								color: color('subtext');
							}
						}
					}
				}
			}
		}
	}

	.buttons
	{
		padding: 0 size(24);
	}

}