@import '/src/css/General.scss';

.text
{
	font-size: rem(12)!important;
	font-weight: bold!important;
	color: color('text')!important;
	margin-bottom: rem(24)!important;
}


.footer-link
{
	margin-top: rem(22);
	display: flex;
	font-size: rem(12);
	font-weight: bold;
	color: color('subtext');
	text-align: center;
	align-items: center;
	justify-content: center;
	i
	{
		font-size: 1.1em;
		margin-bottom: -0.1em;
		margin-right: 0.2em;
	}
}
