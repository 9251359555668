@import 'src/css/General.scss';

.LoginPage
{
	.reset-password
	{
		@extend .bold;
		font-size: size(12);
		color: color('subtext');
		display: flex;
		justify-content: flex-end;
	}
}