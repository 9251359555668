@import '/src/css/General.scss';
@import '/src/core/screens/Page.scss';

.header
{
	img.thumbnail
	{
		width: 100%;
	}
	margin-bottom: 2em;
}
.container
{
	@include padding;

	[id]
	{
		scroll-margin-top: size(64);
	}

	.title
	{
		margin-bottom: size(16, 24)!important;
	}

	.tags
	{
		color: color('primary');
		font-size: size(15);
		a
		{
			text-decoration: none;
		}
		.tag
		{
			@extend .bold;
			display: inline;
			margin-right: 0.4em;
		}
	}

	.content
	{
		margin-top: size(40);
	}

	a
	{
		text-decoration: underline;
	}
	
	iframe
	{
		width: 100%;
		height: none !important;
	}

	h1
	{
		font-size: size(24);
	}

	h2
	{
		font-size: size(20);
	}

	h3
	{
		font-size: size(17);
	}

	h1, h2, h3
	{
		margin-top: 0.4em;
		margin-bottom: 0.4em;
		&>*
		{
			display: inline;
		}

		.ribbon
		{
			display: inline-block;
			color: color('primary');
			margin-right: 0.4em;
			transform: translateY(0.1em);

		}
	}

	p
	{
		margin-top: 1em;
		margin-bottom: 1em;
	}

	ol
	{
		list-style-type: decimal;
		li
		{
			margin-top: 0.4em;
			margin-bottom: 0.4em;
			margin-left: 2em;
		}
	}

	hr
	{
		border-top: 0;
		border-bottom: 1px solid color('border');
	}

	.image
	{
		img
		{
			width: 100%;
			margin-bottom: 0;
			display: block;
		}

		.caption
		{
			margin-top: 0;;
			font-size: size(10);
		}
	}
	

	.callout
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		font-size: size(12);
	}

	.twitter
	{
		@include padding;
		height: size(430);
	}

	.instagram
	{
		@include padding;
		height: size(400);
	}
	.youtube
	{
		height: size(210);
	}
}