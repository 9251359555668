@import 'src/css/General.scss';
@import './Pluspage.scss';


.PlusPreviewPage{

	.body_card{
		min-height: calc(100vh - 17px - 64px);

		.title{
			text-align: center;
			font-size: 24px;
			font-weight: 700;
		}

		.previewbox{
			position: relative;
			width: 200px;
			height: 200px;
			margin: 24px auto;

			.preview-image{
				width: 100%;
				height: 100%;
				border-radius: 4px;

        label
        {
          font-size: rem(12);
          color: color('subtext');
          font-weight: bold;
          position: absolute;
          top: rem(110);
          white-space: nowrap;
          left: 50%;
          transform: translateX(-50%);
        }

				img{
					width: 100%;
					height: 100%;
					border-radius: 4px;
				}
			}

			svg{
				position: absolute;
				top: calc(50% - (75px/2));
				left: calc(50% - (75px/2));
			}
		}

		.covertitlebox{
			border: 1px solid color('border');
			border-radius: 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 20px 0;

			.covertitle{
				font-size: 17px;
				font-weight: 700;
				white-space: pre-wrap;
				text-align: center;
			}

			.change_covertitle{
				margin-top: 10px;
				color: color('subtext');
				font-size: 12px;
				font-weight: 700;
			}
		}

		.savebuttonwrap{
			margin-top: 40px;
		}
	}
}