@import 'src/css/General.scss';

.modal
{
	.modal-container
	{
		text-align: center;
	}
	img
	{
		width: 100%;
		height: auto;
		margin: size(20) auto 0;
	}

	.title
	{
		@extend .h2;
		margin: 0;
		margin-bottom: size(16, 20);
	}

	.description
	{
		@extend .bold;
		font-size: size(15);
		color: color('black');
		// margin-top: size(26, 15);
		margin-bottom: size(12, 15);
		b
		{
			color: color('primary');
		}
	}

	.text
	{
		@extend .caption;
		@extend .bold;
		color: color('subtext');
		margin: 0;
	}

	.buttons
	{
		text-align: center;
		margin-top: size(24);
		.already-recorded
		{
			@extend .bold;
			@extend .caption;
			display: flex;
			align-items: center;
			justify-content: center;
			color: color('subtext');
			text-align: center;
			margin-top: size(20);
			i
			{
				font-size: size(18, 15);
				margin-right: size(2);
			}
		}
		.stop-button
		{
			@extend .bold;
			font-size: size(12);
			display: flex;
			align-items: center;
			justify-content: center;
			color: color('subtext');
			text-align: center;
			margin-top: size(20, 12);
			i
			{
				font-size: size(14, 12);
				margin-right: size(4, 12);
			}
		}
	}

	&.modal-selectVideo{
		.description{
			display: flex;
			justify-content: center;
			font-size: size(13);
			font-weight: normal;
			color: color('Normal');

			i{
				color: color('secondary');
				margin-right: size(7);
			}
		}

		.thumb{
			background-color: color('white2');
			margin-top: size(24);
			padding: size(24) size(12);
			border-radius: size(16);

			display: flex;
			flex-direction: column;

			.text{
				margin-bottom: size(14);
			}

			img{
				max-height: size(115);
				object-fit: contain;
				margin: 0;
			}
		}
	}
}