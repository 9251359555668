@import '/src/css//General.scss';

.saved-toast{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: size(20);
	color: color('white');
	display: flex;
	align-items: center;
	justify-content: center;
	@extend .bold;
	background-color: color('ok');
	transform: translateY(-100%);
	transition: transform 0.2s;
	opacity: .9;
	i
	{
		font-weight: normal;
		margin-right: 0.4em;
	}
	&.show
	{
		transform: translateY(0);
	}
	z-index: 200000;
}


.PurchasedMoviePage
{

	.description
	{
		@extend .caption;
		@extend .bold;
		margin-top: size(44);
		margin-bottom: size(24);
	}

	.notify-purchase
	{
		margin-top: rem(24);

		.notify-users
		{
			font-size: rem(12);
			font-weight: bold;
			color: color('primary');
			text-align: center;
		}
	}

	.steps
	{
		.step
		{
			margin-bottom: size(32);
			display: flex;

			.count{
				flex-shrink: 0;
				margin-top: size(4);
				margin-right: size(4);
				background-color: color('secondary');
				font-family: 'lato';
				color: color('white');
				font-weight: 700;
				font-size: size(14);
				width: size(24);
				height: size(24);
				border-radius: 50%;
				display: flex;
				align-items: center;
    		justify-content: center;
			}

			.title
			{
				@extend .bold;
				margin-bottom: size(6);
				font-size: 17px;
			}

			.text
			{
				@extend .bold;
			}

			.content
			{
				font-size: size(12);
				color: color('subtext');

				.img
				{
					text-align: center;
					img
					{
						width: size(222);
						margin: size(43) auto;
					}
				}
			}
		}
	}

	.preview
	{
		display: block;
		border-radius: size(16);
		overflow: hidden;
		width: calc(min(768px, 100vw) - #{size(48)});
		height: calc(min(768px, 100vw) - #{size(48)});
		background-color: color('inactive');
		margin-top: size(35);
	}

	.expiration, .note
	{
		@extend .caption;
		@extend .bold;
		margin-top: size(12, 12);
		color: color(subtext);
		white-space: pre-wrap;
		b
		{
			color: color('error');
		}
	}
	.buttons
	{
		margin-top: size(40);
	}


}