.ToC
{
	@for $i from 1 through 3 {
		.i_#{$i}
		{
			margin-left: 0.8em * ($i - 1);
		}
	}

	a
	{
		display: block;
		line-height: 2em;
		font-weight: 200;
	}
}